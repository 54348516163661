export const SuccessMessage = {
  login: 'loggedInSuccess',
  logout: 'loggedOutSuccess',
  changePassword: 'passwordUpdateSuccess',
  playerStatusUpdate: 'updatePlayerStatusSuccess',
  createOperator: 'operatorUserCreatedSuccess',
  resetPassword: 'resetPasswordSuccess',
  operatorDelete: 'operatorDeletedSuccess',
  operatorStatusUpdate: 'operatorStatusUpdateSuccess',
  signup: 'signupSuccess'
}

export const ErrorMessage = {
  internalServerError: 'internalServerError',
  unAuthorized: 'unAuthorized'
}

export const errorCodeToMessage = new Map([
  [600, 'internalServerError'],
  [601, 'loginFailedError'],
  [602, 'invalidCredentialsError'],
  [603, 'invalidTokenError'],
  [604, 'invalidSessionError'],
  [605, 'invalidAccessError'],
  [606, 'nonOperationalError'],
  [2001, 'createUserError'],
  [2002, 'operatorNotExistsError'],
  [2003, 'operatorPlayerNotExists'],
  [2004, 'dataMismatchError'],
  [2005, 'userNotFoundError'],
  [2006, 'duplicateEmailError'],
  [2007, 'operatorUserIsBlocked'],
  [3001, 'RequestInputValidationError'],
  [3002, 'ResponseInputValidationError'],
  [3003, 'SocketRequestInputValidationError'],
  [3004, 'SocketResponseValidationError'],
  [3005, 'InternalServerError'],
  [3006, 'InvalidSocketArgumentError'],
  [3007, 'InvalidCredentials'],
  [3008, 'InvalidToken'],
  [3009, 'InvalidSession'],
  [3010, 'InvalidAccess'],
  [3011, 'NonOperationalError'],
  [3012, 'UserNotExists'],
  [3013, 'InvalidAction'],
  [3014, 'SessionAlreadyStarted'],
  [3015, 'SessionNotStarted'],
  [3016, 'EmailNotVerified'],
  [3017, 'InvalidGameTypeError'],
  [3018, 'InvalidGameRoundError'],
  [3019, 'NoRoundRunningError'],
  [3020, 'AllReadyInsuranceFound'],
  [3020, 'NoPlacedBetFoundError'],
  [3021, 'NoWalletFoundErrorr'],
  [3022, 'NotEnoughBalanceError'],
  [3023, 'AutoRateIsInvalidError'],
  [3024, 'BetAmountIsNotInLimitError'],
  [3025, 'EmailNotFound'],
  [3026, 'UserNotAbove18YearsError'],
  [3027, 'InvalidBlockchainAddress'],
  [3028, 'AddressMismatch'],
  [3029, 'NonceLifetimeExpired'],
  [3030, 'SomethingWentWrong'],
  [3031, 'AccountNotActive'],
  [3032, 'LoginTokenRequire'],
  [3033, 'UserAlreadyExists'],
  [3034, 'InvalidVerificationToken'],
  [3035, 'UserNotActive'],
  [3036, 'FileUploadFailed'],
  [3037, 'EmailAlreadyVerified'],
  [3038, 'InvalidReferralCode'],
  [3039, 'InvalidAffiliateCode'],
  [3040, 'RecordNotFound'],
  [3040, 'WithdrawalRequestAlreadyPending'],
  [3041, 'WalletDoesNotBelongToUser'],
  [3042, 'BetAlreadyInProgress'],
  [3043, 'ServerSeedNotFoundErrorType'],
  [3044, 'InvalidMineCountErrorType'],
  [3045, 'InvalidTileErrorType'],
  [3046, 'MineTileAlreadyOpenedErrorType'],
  [3047, 'NoOpenedTileFoundErrorType'],
  [3048, 'MineGamePreviousRoundNotCompletedErrorType'],
  [3049, 'BlackJackPreviousRoundNotCompletedErrorType'],
  [3050, 'BlackJackGameDoubleBetErrorType'],
  [3051, 'BlackJackGameSplitBetErrorType'],
  [3052, 'BlackJackGameSplitHitErrorType'],
  [3052, 'InvalidDiceNumberError'],
  [3053, 'FavoriteGameExistsErrorType'],
  [3053, 'InvalidRouletteBetType'],
  [3054, 'FavoriteGameNotFoundErrorType'],
  [3055, 'TransactionAlreadyPendingErrorType'],
  [3056, 'BlackJackGameInsuranceBetAlreadyPlacedErrorType'],
  [3057, 'BlackJackGameInsuranceBetErrorType'],
  [3058, 'BlackJackGameSplitBetAlreadyPlacedErrorType'],
  [3059, 'InvalidRoundHashErrorType']
])
