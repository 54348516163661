import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import './SelectedMyBetHistory.scss'
import { CloseXIcon } from '../../../../../../icons'
import { setSelectedBetDetailForFairness, setShowDiceSelectBetHistory } from '../../../../../../redux-store/redux-slices/Games/diceGame'
import { copyToClipboard } from '../../../../../../utils/helper'

const SelectedMyBetHistory = () => {
  // const [showFairModal, setShowFairModal] = useState(false)
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { showDiceSelectBetHistory } = useSelector(state => state.diceGame)
  const { systemGameData } = useSelector((state) => state?.gameSetting)
  const { t } = useTranslation('diceGame')

  const { betAmount, clientSeed, createdAt, id, number, rollOver, serverSeed, winningAmount, winningNumber, result } = showDiceSelectBetHistory || {}

  // const { addToast } = useToasts()
  const diceGameSettings = useMemo(() => {
    return systemGameData?.filter(gameData => gameData?.id === '2')?.[0]
  }, [systemGameData])
  useEffect(() => {
    // eslint-disable-next-line
    // if(diceGameSettings) {
    $('#diceSliderMyBet').rangeslider({
      polyfill: false,
      onSlide: function (_, value) {
        // onNumberChange(value)
      }
    })
    // }
  }, [])

  useEffect(() => {
    if (showDiceSelectBetHistory) {
      const inputRange = $('#diceSliderMyBet')
      inputRange.val(+number).change()
    }
  }, [number])

  useEffect(() => {
    const diceImg = document.getElementById('dice-img-box-history')
    diceImg.style.left = `calc(${winningNumber}% - 25px)`
    diceImg.style.display = 'block'
  }, [winningNumber])

  const handleCopyCode = (value) => {
    const isCopySuccessfull = copyToClipboard(value)
    if (!isCopySuccessfull) return addToast(t('selectBetHistoryPopup.messages.copyNotSupported'), { appearance: 'error' })

    addToast(t('selectBetHistoryPopup.messages.copied'), { appearance: 'success' })
    return null
  }
  const handleClose = () => {
    dispatch(setShowDiceSelectBetHistory(null))
  }

  const handleOnVerifyClick = (clientSeed, serverSeed) => {
    if (clientSeed && serverSeed) {
      handleClose()
      dispatch(setSelectedBetDetailForFairness({ clientSeed, serverSeed }))
    }
  }

  const calculateMultiplier = (newNumber = number) => {
    const probability = rollOver ? (100 - newNumber) / 101 : newNumber / 101
    const odds = calculateOdds(diceGameSettings, 1 / probability)
    return odds || 0
  }

  const calculateOdds = (gameSettings, odds) => {
    return (
      Math.floor(
        Math.max(
          parseFloat(gameSettings?.minOdds),
          Math.min(
            parseFloat(gameSettings?.maxOdds),
            odds * (1 - parseFloat(gameSettings?.houseEdge) / 100)
          )
        ) * 100
      ) / 100
    )
  }
  return (
    <>
      <div
        className='modal fade show'
        style={{ display: 'block' }} id='diceSingleHistoryModal'
        onClick={() => handleClose()}
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div
            className='modal-content overflow-hidden'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='modal-header py-3 border-0 px-4'>
              <h5 className='modal-title fs-5 text-white ms-2 text-start'>{t('selectBetHistoryPopup.title')}</h5>
              <button
                type='button'
                className='btn-close small me-1 mt-0'
                onClick={() => {
                  handleClose()
                }}
              ><CloseXIcon />
              </button>
            </div>
            <div className='modal-body px-0 py-0'>
              <div className='text-center text-white fw-bold'>{t('selectBetHistoryPopup.gameName')}</div>
              <div className='text-center text-white fw-bold mb-3 px-3'>{t('selectBetHistoryPopup.gameId')} {id}
                <i
                  className='fas fa-copy text-muted ms-2'
                  role='button'
                  onClick={() => {
                    handleCopyCode(id)
                  }}
                />
              </div>
              <p className='text-center text-white small text-muted mb-3 px-3'>{t('selectBetHistoryPopup.on')} {moment.utc(createdAt).format('MM/DD/YYYY')} {t('selectBetHistoryPopup.at')} {moment.utc(createdAt).format('h:mm A')}</p>
              <div className='modal-logo-section my-4 mx-3'>
                <img src='/assets/images/logo/logo-1.png' className='img-fluid mx-auto bg-line-design' alt='Brand-logo' />
              </div>
              {/* modal dice multiplier section */}
              <div className='dice-modal-multiplier-section p-3 rounded mx-3'>
                <div className='row gx-0'>
                  <div className='col-4 text-center dice-modal-multiplier-col'>
                    <div className='modal-multiplier-title fw-bold text-white small mb-2'>{t('selectBetHistoryPopup.bet')}</div>
                    <div className='modal-multiplier-title-data fw-bold text-white'>{betAmount || '0.000000'}</div>
                  </div>
                  <div className='col-4 text-center dice-modal-multiplier-col'>
                    <div className='modal-multiplier-title fw-bold text-white small mb-2'>{t('selectBetHistoryPopup.multi')}</div>
                    <div className='modal-multiplier-title-data fw-bold text-white'>{betAmount ? (+winningAmount / +betAmount).toFixed(2) : '0.00'}</div>
                  </div>
                  <div className='col-4 text-center dice-modal-multiplier-col'>
                    <div className='modal-multiplier-title fw-bold text-white small mb-2'>{t('selectBetHistoryPopup.payout')}</div>
                    <div className='modal-multiplier-title-data fw-bold text-white'>{winningAmount || '0.00'}</div>
                  </div>
                </div>
              </div>
              {/* Range Slider */}
              <div className='dice-game-wrap dice-history-cards-wrap w-100 pb-0 pt-4'>
                <div className='slider-section bg-transparent px-3 h-auto pb-3'>
                  <div className='mobile-slider-box-border w-100 d-flex align-items-center justify-content-center'>
                    <div className='slider-box my-5 mx-4 mx-lg-0'>
                      <div
                        className='slider-scale fw-bold d-flex justify-content-between text-white'
                      >
                        <span>0</span>
                        <span>25</span>
                        <span>50</span>
                        <span>75</span>
                        <span>100</span>
                      </div>
                      <div className='inner-box'>
                        <input
                          type='range'
                          id='diceSliderMyBet'
                          min='0'
                          max='100'
                          step='1'
                        />
                        <div style={{ position: 'relative', margin: '0px 24px' }}>
                          <div className='dice-img-box' id='dice-img-box-history'>
                            <img
                              src='/assets/images/dice/dice-slider.png'
                              className='img-fluid dice-img'
                              alt='Dice Img'
                            />
                            <span className={`dice-status ${result === 'won' ? 'status-win' : 'status-lose'}`}>
                              {winningNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* multiplier section start */}
                  <div className='mb-3'>
                    {/* Add component here */}
                    <div className='form-box'>
                      <div className='row gx-3 gx-sm-2 gx-md-3'>
                        <div className='col-4 mb-4 mb-md-3'>
                          <label className='form-label'>{t('selectBetHistoryPopup.multi')}</label>
                          <div className='input-holder position-relative'>
                            <input
                              type='number'
                              className='form-control'
                              placeholder={t('selectBetHistoryPopup.multi')}
                              step='any'
                              min={0}
                              value={calculateMultiplier(number)}
                            />
                            <button type='button' className='input-btn'>
                              <img src='/assets/images/dice/cross.png' alt='Img' width='15' />
                            </button>
                          </div>
                        </div>
                        <div className='col-4 mb-4 mb-md-3'>
                          <label className='form-label'>{rollOver ? t('selectBetHistoryPopup.rollOver') : t('selectBetHistoryPopup.rollUnder')}</label>
                          <div className='input-holder position-relative'>
                            <input
                              type='number'
                              className='form-control'
                              value={number}
                              readOnly
                            />
                            <button
                              type='button'
                              className='input-btn'
                            >
                              <img src='/assets/images/dice/reload.png' alt='Img' width='20' />
                            </button>
                          </div>
                        </div>
                        <div className='col-4 mb-4 mb-md-3'>
                          <label className='form-label'>{t('selectBetHistoryPopup.winChance')}</label>
                          <div className='input-holder position-relative'>
                            <input
                              type='number'
                              className='form-control'
                              value={
                                rollOver
                                  ? (100.0 - number)?.toFixed(2)
                                  : number?.toFixed(2)
                              }
                              placeholder={t('selectBetHistoryPopup.winChance')}
                            />
                            <button type='button' className='input-btn'>
                              <img src='/assets/images/dice/percent-icon.png' alt='Img' width='18' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type='button'
                    className='btn btn-primary text-decoration-none text-white mb-3'
                    onClick={() => {
                      handleClose()
                    }}
                  >{t('selectBetHistoryPopup.playDice')}
                  </button>
                  {/* multiplier section end */}
                </div>
                {/* round hash section */}
                <div className='result-link-section p-3'>
                  <div className='form-group mb-3'>
                    <label className='text-white'>{t('selectBetHistoryPopup.clientSeed')}</label>
                    <div className='input-group'>
                      <input aria-label='Username' type='text' value={clientSeed || ''} className='form-control pe-5' aria-describedby='button-addon1' />
                      <button type='but ton' className='btn bet-amount-btn addon addon-right input-group-text py-0' onClick={() => handleCopyCode(clientSeed || '')}>
                        <i className='fas fa-copy' />
                      </button>
                    </div>
                  </div>
                  <div className='form-group mb-3'>
                    <label className='text-white'>
                      {t('selectBetHistoryPopup.serverSeed')}
                    </label>
                    <div className='input-group'>
                      <input aria-label='Username' type='text' value={serverSeed || ''} className='form-control pe-5' aria-describedby='button-addon1' />
                      <button type='button' className='btn bet-amount-btn addon addon-right input-group-text py-0' onClick={() => handleCopyCode(serverSeed || '')}>
                        <i className='fas fa-copy' />
                      </button>
                    </div>
                  </div>
                  <div className='text-center pt-3'>
                    <a href='#' className='d-block btn btn-primary text-decoration-none text-white mb-3' data-bs-toggle='modal' data-bs-target='#fairnessModal' onClick={() => handleOnVerifyClick(clientSeed, serverSeed)}>
                      <span>{t('selectBetHistoryPopup.verifyGame')}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(SelectedMyBetHistory)
