import React, { useState, memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import { MaximizeIcon, MinimizeIcon, StarFillIcon, StarIcon } from '../../icons'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import { getSelectedCasinoGame, launchCasinoGame } from '../../redux-store/redux/thunk/Games/casinoGame'
import './home.scss'
import { addFavouriteGame, removeFavouriteGame } from '../../redux-store/redux/thunk/game'
import { setShowSideBetList } from '../../redux-store/redux-slices/settings'
import { setSelectedCasinoGame } from '../../redux-store/redux-slices/Games/casinoGame'

const HomeCasinoGamePlay = () => {
  const [iframeLoading, setIframeLoading] = useState(true)
  const [isTheatreMode, setIsTheatreMode] = useState(false)
  const [isDemoMode, setIsDemoMode] = useState(false)

  const dispatch = useDispatch()
  const isLoggedIn = getAuthToken()
  const { gameId, gameCode } = useParams()

  const { casinoLaunchData, selectedCasinoGame } = useSelector(state => state.casinoGame)
  const { game_url: gameUrl } = casinoLaunchData?.launchData || {}
  const isGameFav = selectedCasinoGame?.isFavourite ?? casinoLaunchData?.isFavorite
  const { hasDemo } = selectedCasinoGame || {}

  useEffect(() => {
    if (!isLoggedIn && hasDemo) setIsDemoMode(true)
    if (isLoggedIn || (!isLoggedIn && hasDemo)) handleLaunchCasino(!isLoggedIn && hasDemo)
  }, [isLoggedIn, hasDemo])

  useEffect(() => {
    (async () => {
      if (!selectedCasinoGame) {
        const { payload: { res } } = await dispatch(getSelectedCasinoGame({ gameCode, offset: 0, limit: 1 }))
        dispatch(setSelectedCasinoGame(res?.rows?.[0]))
      }
    })()
  }, [selectedCasinoGame])

  const handleLaunchCasino = (isDemo) => {
    // const { gameCode, hasDemo } = gameData
    const payload = {
      currency: 'USD',
      gameCode,
      country: 'IN',
      lang: 'eng',
      device: 'DESKTOP',
      // funMode: !!hasDemo,
      funMode: isDemo,
      jurisdiction: 'CW',
      betLimits: 'medium'
    }
    dispatch(launchCasinoGame({ payload }))
  }

  const handleIframeLoad = () => {
    setIframeLoading(false)
  }

  useEffect(() => {
    dispatch(setShowSideBetList(false))
    // Add the class to the body when the component mounts
    document.body.classList.add('home-casino-iframe-styles')

    // Remove the class from the body when the component unmounts
    return () => {
      document.body.classList.remove('home-casino-iframe-styles')
    }
  }, [])

  const favouriteGameHandler = (e) => {
    e?.stopPropagation()
    const game = { id: gameId, isFavourite: false }
    if (isGameFav) {
      dispatch(removeFavouriteGame({ game, gameData: selectedCasinoGame }))
    } else {
      dispatch(addFavouriteGame({ game, gameData: selectedCasinoGame }))
    }
  }

  const handleDemoModeChange = () => {
    setIsDemoMode(prev => !prev)
    handleLaunchCasino(!isDemoMode)
  }

  return (
    <section className='game-section blackjack-section'>
      <div className={`container ${!isTheatreMode ? 'shrink-container' : ''}`}>
        <div className='game-wrap overflow-visible'>
          <div className='casino-iframe-container'>
            {iframeLoading && !gameUrl && <Loader />}
            <iframe
              src={gameUrl}
              loading='eager'
              onLoad={handleIframeLoad}
              style={{ display: iframeLoading ? 'none' : 'block' }}
              className='mx-auto'
            />
          </div>
        </div>
        <div className='footer-controllers-box'>
          <div className='grp-btn-box'>
            <button type='button' className='btn text-btn d-none d-md-block' onClick={() => setIsTheatreMode(prev => !prev)} data-placement='top' data-toggle='tooltip' title='Theater'>
              {isTheatreMode ? <MaximizeIcon /> : <MinimizeIcon />}
            </button>
            <button type='button' className='btn text-btn' data-placement='top' data-toggle='tooltip' title='fav' onClick={favouriteGameHandler}>
              {isGameFav ? <StarFillIcon /> : <StarIcon />}
            </button>
            <div class='form-check form-switch'>
              <input class='form-check-input' type='checkbox' role='switch' id='flexSwitchCheckDefault' disabled={!hasDemo} value={isDemoMode} onChange={handleDemoModeChange} />
              <label class='form-check-label' for='flexSwitchCheckDefault'>{!isDemoMode ? 'Demo Mode' : 'Game Mode'}</label>
            </div>
          </div>
          <div className='logo-box'>
            <img src='/assets/images/logo/logo-1.png' className='img-fluid d-block mx-auto' alt='Brand Logo' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(HomeCasinoGamePlay)
