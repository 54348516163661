import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ErrorMessage from './../../components/ErrorMessage'
import Loader from '../Loader'
import { LOADER } from '../../utils/constants'

const ForgetPasswordContent = ({ handleForgetPasswordSubmit, loading, id }) => {
  const { t } = useTranslation('home')

  const schema = yup.object().shape({
    userNameOrEmail: yup
      .string()
      .required(t('signup.errors.email.required'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signup.errors.email.pattern')
      )
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  useEffect(() => {
    const modalElement = document.getElementById(id)
    const modalHideHandler = () => {
      reset() // Reset the form state when the modal is hidden
    }

    modalElement.addEventListener('hide.bs.modal', modalHideHandler)

    return () => {
      modalElement.removeEventListener('hide.bs.modal', modalHideHandler)
    }
  }, [reset])

  return (
    <form onSubmit={handleSubmit(handleForgetPasswordSubmit)}>
      <div className='row justify-content-center'>
        <div className='col-sm-12'>
          <div className='mb-3'>
            <label className='form-label'>{t('forgetPass.email')} <span className='text-danger'>*</span></label>
            <div className='input-group'>
              <input
                type='email'
                className='form-control'
                name='userNameOrEmail'
                maxLength={50}
                {...register('userNameOrEmail')}
              />
            </div>
            {errors && errors.userNameOrEmail && (
              <ErrorMessage
                className='text-danger error-msg login-input small'
                message={errors.userNameOrEmail.message}
              />
            )}
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='btn-box mt-2'>
            <button
              type='submit'
              className='btn secondary-btn w-100'
              disabled={loading}
            >
              {loading
                ? (
                  <Loader component={LOADER.BUTTON} />
                  )
                : (
                    t('forgetPass.recoverBtn')
                  )}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ForgetPasswordContent
