export const HighlightedBetsTabs = [
  {
    id: 'myBets',
    name: 'betsTable.tableTabs.myBets'
  },
  {
    id: 'allBets',
    name: 'betsTable.tableTabs.allBets'
  },
  {
    id: 'highRoller',
    name: 'betsTable.tableTabs.highRoller'
  }
  // {
  //   id: 'raceLeaderBoard',
  //   name: 'Race Leaderboard'
  // },
]

export const NumberOfRecordList = [0, 10, 20, 30, 40]
