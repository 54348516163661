import React, { useCallback, useEffect } from 'react'
import SumsubWebSdk from '@sumsub/websdk-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getSumsubTokenService } from '../../network/services/sumsub.service'
import { getSumsubToken } from '../../redux-store/redux/thunk/sumsub.thunk'
import { setSumsubToken } from '../../redux-store/redux-slices/sumsub.slice'
import { ROUTE_PATHS } from '../../utils/constants'

const SettingsSection = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { user } = useSelector((state) => state.user)
  const { sumsubTokens } = useSelector((state) => state.sumsub)

  useEffect(() => {
    if (pathname === ROUTE_PATHS.KYC) {
      dispatch(getSumsubToken())
    }
    return () => {
      dispatch(setSumsubToken(null))
    }
  }, [pathname])

  const config = {
    lang: 'en',
    country: 'USA',
    email: user?.email,
    phone: '4989898989'
  }

  const messageHandler = useCallback((data, payload) => {
    // console.log({ data, payload })
  }, [])

  const errorHandler = useCallback((error) => {
    console.log('sumsub error: ', error)
  }, [])

  const accessTokenExpirationHandler = (tokenLevel) => {
    const promise = getSumsubTokenService()
    return promise
      .then((data) => {
        return data[tokenLevel].token
      })
      .catch((error) => {
        return error
      })
  }

  return (
    <main className='main-section'>
      <section className='transaction-section profile-inner-section'>
        <div className='container'>
          <h1 className='section-heading'>KYC-Verification</h1>
          {/* <div className='profile-inner-wrap'> */}
          {sumsubTokens &&
            <SumsubWebSdk
              accessToken={sumsubTokens?.token}
              expirationHandler={() => accessTokenExpirationHandler()}
              config={config}
              onMessage={messageHandler}
              onError={errorHandler}
            />}
          {/* </div> */}
        </div>
      </section>
    </main>
  )
}

export default SettingsSection
