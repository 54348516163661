import { SuccessMessage } from '../../helpers/messages'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants'
import axiosInstanceService, { microServices } from '../apis'

export const getSumsubTokenService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/kyc-verification/create-access-token', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const updateUserInformationLevelOneKycService = (data) => {
  return axiosInstanceService(METHOD_TYPES.put, '/kyc-verification/update-kyc', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.kycRequestSubmittedSuccessfully
  })
}
