import React, { useCallback } from 'react'
// import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-use'
import { userSignUp } from '../../redux-store/redux/thunk/Auth/Auth'
import { setLoader } from '../../redux-store/redux-slices/Users/user'
import GamePopup from '../../components/GamePopup'
import RegisterPopupContent from '../../components/GamePopupContent/RegisterPopupContent'
import { closeBootStrapModal } from '../../utils/helper'
import './register.scss'

const Register = ({ setShowPopup, showSignupPopup, handleSignupPopClose }) => {
  // const { t } = useTranslation('home')
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const affiliateCode = new URLSearchParams(search).get('affiliateCode')
  const { loading } = useSelector(state => state.user)
  const { siteInfo } = useSelector(state => state.gameSetting)

  const { addToast } = useToasts()

  const handleRegisterSubmit = useCallback(async (userDetails, reset) => {
    if (affiliateCode) {
      userDetails.affiliatedBy = affiliateCode
    }
    try {
      dispatch(setLoader(true))
      dispatch(
        userSignUp({
          userDetails,
          addToast,
          setShowPopup,
          reset,
          history
        })
      )
    } catch (e) {
      addToast(e.message, { appearance: 'error' })
    }
  }, [affiliateCode, addToast])

  const handleRedirectToTnC = useCallback(() => {
    const info = siteInfo?.find(info => info?.slug === 'terms-condition')
    if (info) {
      closeBootStrapModal('#registerModal')
      history.push(`/site-info/${info?.slug}`)
    }
  }, [siteInfo])

  return (
    <>
      <GamePopup id='registerModal' title='Create an Account' className={`auth-modal-form ${showSignupPopup ? 'show d-block' : ''}`} handleClose={handleSignupPopClose}>
        <RegisterPopupContent handleRegisterSubmit={handleRegisterSubmit} loading={loading} handleRedirectToTnC={handleRedirectToTnC} />
      </GamePopup>
    </>
  )
}

export default Register
