import React from 'react'
import { RecycleIcon } from '../../icons'
import { useTranslation } from 'react-i18next'

const LiveStatsContent = ({ liveStatsData, resetLiveStats }) => {
  const { t } = useTranslation('gameCommon')
  return (
    <>
      <div className='d-flex justify-content-between align-content-center'>
        <div className='dropdown text-white h5'>
          {t('liveStatsContent.bets')}
          {/* <button className='btn primary-btn' type='button' id='liveStatsDropdown' data-bs-toggle='dropdown' aria-expanded='false'>
            <span className='text'>All</span>
            <FontAwesomeIcon icon={faAngleDown} className='ms-auto' />
          </button>
          <ul className='dropdown-menu' aria-labelledby='liveStatsDropdown' style={{ minWidth: '4rem' }}>
            <li>
              <a href='#' className='dropdown-item'>
                <span className='text'>All</span>
              </a>
            </li>
            <li>
              <a href='#' className='dropdown-item'>
                <span className='text'>Race</span>
              </a>
            </li>
            <li>
              <a href='#' className='dropdown-item' onClick={() => closeModal('liveStatsModal')}>
                <span className='text'>Hide</span>
              </a>
            </li>
          </ul> */}
        </div>
        <button type='button' className='btn text-btn' onClick={resetLiveStats}>
          <RecycleIcon />
        </button>
      </div>
      <div className='content-wrap content-wrap-border mt-3'>
        <div className='content-box content-border'>
          <div className='row g-2'>
            <div className='col-7'>
              <div className='bets-text-box my-1'>
                <span className='text-1'>{t('liveStatsContent.profit')}</span>
                <span className='text-2'>
                  <span className={`text ${liveStatsData?.profit < 0 ? 'text-red' : 'text-green'}`}>{liveStatsData?.profit || '0.00000000'}</span>
                  <span className='icon'>
                    <img
                      src='/assets/images/currency/currency-brl.png'
                      className='img-fluid'
                      alt='Icon'
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                </span>
              </div>
              <div className='bets-text-box my-1'>
                <span className='text-1'>{t('liveStatsContent.wagered')}</span>
                <span className='text-2'>
                  <span className='text'>{liveStatsData?.wagered || '0.00000000'}</span>
                  <span className='icon'>
                    <img
                      src='/assets/images/currency/currency-brl.png'
                      className='img-fluid'
                      alt='Icon'
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className='col-5'>
              <div className='bets-text-box my-1'>
                <span className='text-1'>{t('liveStatsContent.win')}</span>
                <span className='text-2'>
                  <span className='text text-green'>{liveStatsData?.wins || '0'}</span>
                </span>
              </div>
              <div className='bets-text-box my-1'>
                <span className='text-1'>{t('liveStatsContent.losses')}</span>
                <span className='text-2'>
                  <span className='text text-red'>{liveStatsData?.losses || '0'}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='content-wrap content-wrap-border mt-3'>
        <div className='content-box content-border' style={{ minHeight: '10rem' }}>
          <div className='bets-text-box'>
            <span className='text-2'>
              <span className='text text-green'>0.00000000</span>
              <span className='icon'>
                <img
                  src="/assets/images/currency/currency-brl.png"
                  className="img-fluid"
                  alt="Icon"
                  style={{ width: '20px', height: '20px'}}
                />
              </span>
            </span>
          </div>
        </div>
      </div> */}
      {/* <div className='content-wrap content-wrap-border mt-3'>
        <div className='content-box content-border' style={{ minHeight: '8rem' }}>
          <div className='dropdown' style={{ width: 'fit-content' }}>
            <button className='btn text-btn' type='button' id='liveStatsDropdown' data-bs-toggle='dropdown' aria-expanded='false'>
              <span className='text'>$100,000 Race - 24 Hours</span>
              <FontAwesomeIcon icon={faAngleDown} className='ms-auto' />
            </button>
            <ul className='dropdown-menu' aria-labelledby='liveStatsDropdown' style={{ marginTop: '-0.75rem' }}>
              <li>
                <a href='#' className='dropdown-item'>
                  <span className='text'>$100,000 Race - 24 Hours</span>
                </a>
              </li>
            </ul>
          </div>
          <div className='d-flex justify-content-center align-items-center py-3'>
            <p className='pera-text'>Wager to enter the race!</p>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default LiveStatsContent
