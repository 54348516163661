import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { loginWithOtp } from '../../redux-store/redux/thunk/Users/User'
import { setLoader, showLoginOtpHandle } from '../../redux-store/redux-slices/Users/user'
const LoginOtpPopUp = ({ showPopup, handleClose }) => {
  const { loginData } = useSelector(state => state.authSlice)
  const [enterCode, setEnterCode] = useState(null)
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { t } = useTranslation()

  return (
    <>
      <div className='modal' style={{ display: 'block' }}>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='content-box'>
                <button
                  type='button' className='btn-close' data-bs-dismiss='modal' onClick={() => showPopup &&
                    dispatch(showLoginOtpHandle(false))}
                />
                <div className='row g-0'>
                  <div className='col-md-12'>
                    <div className='side-content-box'>
                      <h1 className='modal-title'>{t('LoginwithOtp')}</h1>
                      <form onSubmit={(e) => {
                        e.preventDefault()
                        dispatch(setLoader(true))
                        const param = { ...loginData, userToken: Number(enterCode) }
                        dispatch(loginWithOtp({ param, addToast, showLoginOtpHandle }))
                      }}
                      >
                        <div className='row g-2'>

                          <div className='col-md-12'>
                            <div className='form-group'>
                              <label htmlFor='inputOtpCode' className='text-uppercase'>{t('EnterLoginOtp')}</label>
                              <input
                                type='number'
                                className='form-control'
                                id='inputOtpCode'
                                maxLength={6}
                                value={enterCode}
                                onChange={(e) => {
                                  if (e.currentTarget.value) {
                                    setEnterCode(e.currentTarget.value)
                                  } else {
                                    setEnterCode(null)
                                  }
                                }}
                              />
                              {/* {passwordError && passwordError.confirmPassword && (
                    <ErrorMessage className='color-red error-msg' message={passwordError.confirmPassword.message} />
                  )} */}
                            </div>
                          </div>
                          <div className='col-md-12 mb-3'>
                            <button className='btn mainBtn w-100'>{t('submit')}</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default LoginOtpPopUp
