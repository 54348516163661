import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { capitalize } from 'lodash'
import { copyToClipboard } from '../../../../utils/helper'
import { setRoundData } from '../../../../redux-store/redux-slices/Users/user'
import { getAllbetOfOneRound } from '../../../../redux-store/redux/thunk/Games/crashGame'
import './RoundHistoryModal.scss'
import { CloseXIcon } from '../../../../icons'
import { setSelectedRoundHashForFairness } from '../../../../redux-store/redux-slices/Games/crashGame'

const RoundHistory = () => {
  // const [showFairModal, setShowFairModal] = useState(false)
  const { t } = useTranslation('crashGame')
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { roundData } = useSelector(state => state.user)
  const { allBetsOneRound } = useSelector(state => state.crashGame)

  // const { addToast } = useToasts()

  const handleCopyCode = (value) => {
    const isCopySuccessfull = copyToClipboard(value)
    if (!isCopySuccessfull) return addToast(t('messages.copyNotSupported'), { appearance: 'error' })

    addToast(t('messages.copied'), { appearance: 'success' })
    return null
  }
  const handleClose = () => {
    dispatch(setRoundData(null))
  }

  const handleOnVerifyClick = (selectedRoundHash) => {
    if (selectedRoundHash) {
      handleClose()
      dispatch(setSelectedRoundHashForFairness(selectedRoundHash))
    }
  }

  useEffect(() => {
    const params = { roundId: roundData?.roundId.toString() }
    dispatch(getAllbetOfOneRound({ addToast, params }))
  }, [roundData?.roundId])

  return (
    <>
      <div
        className='modal fade show auth-modal-form'
        style={{ display: 'block' }} id='roundHistoryModal'
        onClick={() => handleClose()}
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div
            className='modal-content overflow-hidden'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='modal-header py-3 border-0 px-4'>
              <h5 className='modal-title fs-5 text-white ms-2 text-start'>{t('roundHistoryPopup.gameName')}</h5>
              <button
                type='button'
                className='btn-close small me-1 mt-0'
                onClick={() => {
                  handleClose()
                }}
              ><CloseXIcon />
              </button>
            </div>
            <div className='modal-body px-0 pb-0'>
              <div className='text-center text-white fw-bold mb-3 px-3'>{t('roundHistoryPopup.gameName')}: {roundData?.id}
                <i
                  className='fas fa-copy text-muted ms-2'
                  role='button'
                  onClick={() => {
                    handleCopyCode(roundData?.id)
                  }}
                />
              </div>
              <p className='text-center text-white small text-muted mb-3 px-3'>{t('roundHistoryPopup.on')} {moment.utc(roundData?.createdAt).format('MM/DD/YYYY')} {t('roundHistoryPopup.at')} {moment.utc(roundData?.createdAt).format('h:mm A')}</p>
              <div className='px-3'>
                <ul className='nav nav-pills mb-3 d-flex mx-auto' id='pills-tab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button className='nav-link active' id='pills-home-tab' data-bs-toggle='pill' data-bs-target='#pills-result' type='button' role='tab' aria-controls='pills-home' aria-selected='true'>{t('roundHistoryPopup.result')}</button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button className='nav-link' id='pills-contact-tab' data-bs-toggle='pill' data-bs-target='#pills-leaderboard' type='button' role='tab' aria-controls='pills-contact' aria-selected='false'>{t('roundHistoryPopup.leaderboard')}</button>
                  </li>
                </ul>
              </div>
              <div className='tab-content' id='pills-tabContent'>
                <div className='tab-pane fade px-3 pb-3 show active  text-white' id='pills-result' role='tabpanel' aria-labelledby='pills-home-tab'>
                  <h3 className='text-center fw-bolder mb-3 py-1'>{roundData?.crashRate}x</h3>
                  <div className='result-link-section p-3'>
                    <div className='form-group mb-3'>
                      <label className='text-white'>{t('roundHistoryPopup.roundHash')}</label>
                      <div className='input-group'>
                        <input aria-label='Username' type='text' value={roundData?.roundHash || ''} className='form-control pe-5' aria-describedby='button-addon1' />
                        <button type='button' className='btn bet-amount-btn addon addon-right py-0' onClick={() => handleCopyCode(roundData?.roundHash || '')}>
                          <i className='fas fa-copy' />
                        </button>
                      </div>
                    </div>
                    <div className='form-group mb-3'>
                      <label className='text-white'>
                        {t('roundHistoryPopup.roundSign')}
                        {/* <i className='fas fa-info-circle' />
                        <i className='fas fa-external-link-alt' /> */}
                      </label>
                      <div className='input-group'>
                        <input aria-label='Username' type='text' value={roundData?.roundSignature || ''} className='form-control pe-5' aria-describedby='button-addon1' />
                        <button type='button' className='btn bet-amount-btn addon addon-right py-0' onClick={() => handleCopyCode(roundData?.roundSignature || '')}>
                          <i className='fas fa-copy' />
                        </button>
                      </div>
                    </div>
                    <div className='text-center pt-3'>
                      <a href='#' className='d-block btn btn-primary text-decoration-none text-white mb-3' data-bs-toggle='modal' data-bs-target='#fairnessModal' onClick={() => handleOnVerifyClick(roundData?.roundHash)}>
                        <span>{t('roundHistoryPopup.verifyBtn')}</span>
                      </a>
                      {/* <a href='#' className='d-block text-decoration-none text-white'>
                        <span>What is Provable Fairness?</span>
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className='tab-pane fade show text-white px-3 pb-3' id='pills-leaderboard' role='tabpanel' aria-labelledby='pills-home-tab'>
                  {/* Leaderboard table */}
                  <div className='leaderboard-container container text-white rounded'>
                    {allBetsOneRound?.bets?.length > 0
                      ? allBetsOneRound?.bets?.map(data => (
                        <div key={data?.userId} className='row flex-nowrap justify-content-between'>
                          <div className='col-4'>
                            <span className='text-muted'>{capitalize(data?.user?.userName || '') || t('roundHistoryPopup.user')}</span>
                          </div>
                          <div className='col-3'>
                            <span>{data?.escapeRate ? `${data?.escapeRate}` : '0.00'}×</span>
                          </div>
                          <div className='col-4'>
                            <img src='/assets/images/common/currency-btc.png' alt='dollor' width='15' className='me-1' />
                            <span className='text-muted'>{data?.escapeRate ? (Number(data?.winningAmount - data?.betAmount)).toFixed(2) : '0.00'} </span>
                          </div>
                        </div>
                        ))
                      : <div className='text-center'>{t('roundHistoryPopup.noData')}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(RoundHistory)
