import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { capitalize } from 'lodash'
import { getRecentlyPlayedGames } from '../../redux-store/redux/thunk/game'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import ImageLazyLoad from '../../components/ImageLazyLoad'
import { ROUTE_PATHS } from '../../utils/constants'
import { setSelectedCasinoGame } from '../../redux-store/redux-slices/Games/casinoGame'

const limit = 20
const customGamesPage = {
  crash: {
    path: ROUTE_PATHS.CRASH_GAME,
    imgUrl: '/assets/images/stock-images/crash-img.png'
  },
  blackjack: {
    path: ROUTE_PATHS.BLACKJACKGAME,
    imgUrl: '/assets/images/stock-images/blackjack-img.png'

  },
  roulette: {
    path: ROUTE_PATHS.ROULETTE,
    imgUrl: '/assets/images/stock-images/roulette-img.png'
  },
  dice: {
    path: ROUTE_PATHS.DICE_GAME,
    imgUrl: '/assets/images/stock-images/dice-img.png'
  }
}

const GAME_TYPES = {
  CUSTOM: 'custom',
  CASINO: 'casino'
}

const RecentlyPlayedGamesSection = () => {
  const dispatch = useDispatch()
  const isLoggedIn = getAuthToken()
  const history = useHistory()
  const { recentlyPlayedGames } = useSelector(state => state.games)
  console.log('recentlyPlayedGames: ', recentlyPlayedGames)

  const goToPlayGamePage = useCallback(async (gameData) => {
    const { gameCode, gameId } = gameData
    if (isLoggedIn) {
      dispatch(setSelectedCasinoGame(gameData))
      history.push(`/casino/play-game/${gameId}/${gameCode}`)
    }
  }, [isLoggedIn])

  useEffect(() => {
    // Add the class to the body when the component mounts
    document.body.classList.add('home-wrap-styles')
    dispatch(getRecentlyPlayedGames({ offset: 0, limit }))
    // Remove the class from the body when the component unmounts
    return () => {
      document.body.classList.remove('home-wrap-styles')
    }
  }, [])

  const onCustomGameClick = (game) => {
    history.push(customGamesPage[game?.gameName].path)
  }

  return (
    <section className='main-section'>
      <section className='home-filter-section'>
        <div className='container'>
          <h2 className='text-white mb-2'>Recently Played Games</h2>
          <div className='casino-container row'>
            {recentlyPlayedGames?.count
              ? recentlyPlayedGames?.rows?.map(game => {
                  if (game?.type === GAME_TYPES.CUSTOM) {
                    return (
                      <div
                        className='casino-game col-6 col-md-4 col-lg-3 mb-3'
                        type='button'
                        key={`${GAME_TYPES.CUSTOM}${game?.gameId}`}
                        onClick={() => onCustomGameClick(game)}
                      >
                        <ImageLazyLoad
                          style={{ objectFit: 'contain' }}
                          src={customGamesPage[game?.gameName]?.imgUrl}
                          placeholderImg='/assets/images/casino/AH_CASINO_FALLBACK.jpeg'
                        />
                        <div className='live-player'>
                          <div className='text-white'>
                            {capitalize(game?.gameName)} Game
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div
                      className='casino-game col-6 col-md-4 col-lg-3 mb-3'
                      type='button'
                      key={`${GAME_TYPES.CASINO}${game?.gameId}`}
                      onClick={() => goToPlayGamePage(game)}
                    >
                      <ImageLazyLoad
                        src={game?.image}
                        placeholderImg='/assets/images/casino/AH_CASINO_FALLBACK.jpeg'
                      />
                      <div className='live-player'>
                        <div className='text-white'>
                          {game?.gameName}
                        </div>
                      </div>
                    </div>
                  )
                })
              : (
                <div className='d-flex justify-content-center mt-5 text-white h4'>No games found.</div>
                )}
          </div>
        </div>
      </section>
    </section>
  )
}

export default RecentlyPlayedGamesSection
