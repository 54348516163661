import { createSlice } from '@reduxjs/toolkit'
import { getAllCurrency, getAllSiteinfo, getBanner, getEncourageWordsSetting, getGameIframe, getGameImages, getGameSetting, getGameThemeSetting, getSponser, getWonderGirlsImages } from '../redux/thunk/gameSetting'
import { generateClientSeed } from '../../utils/common-functions'

const initialState = {
  systemGameData: null,
  allCurrencies: [],
  gameSettings: [],
  gameSettingLoader: false,
  banner: [],
  gameSound: false,
  gameMusic: false,
  showLoginPopup: false,
  showSignupPopup: false,
  affilateCode: null,
  homeBanner: [],
  gameThemeSetting: null,
  sponserBannerLoading: false,
  encourageSettings: [],
  isDemoGame: false,
  demoWallet: 3000,
  gameIframeLink: null,
  clientSeed: generateClientSeed(),
  allWonderGirls: [],
  allGameImages: [],
  siteInfo: null,
  gameWiseSounds: {
    crash: +JSON.parse(sessionStorage.getItem('gameSounds'))?.crash || 1, // 0 -> 1
    blackjack: +JSON.parse(sessionStorage.getItem('gameSounds'))?.blackjack || 1, // 0 -> 1
    dice: +JSON.parse(sessionStorage.getItem('gameSounds'))?.dice || 1, // 0 -> 1
    roulette: +JSON.parse(sessionStorage.getItem('gameSounds'))?.roulette || 1 // 0 -> 1
  }
}

const {
  actions: { // 0 -> 1
    setAllcurrency,
    setSystemData,
    setGameSettings,
    setGameSettingLoader,
    setMusicSound,
    setGameSound,
    setShowLoginPopup,
    setShowSignupPopup,
    setAffilateCode,
    setNewSeed,
    setIsDemoGame,
    setDemoWallet,
    setGameWiseSounds
  },
  reducer
} = createSlice({
  name: 'gameSetting',
  initialState,
  reducers: {
    setSystemData: (state, action) => ({
      ...state,
      systemGameData: [...action.payload]
    }),
    setGameSound: (state, action) => ({
      ...state,
      gameSound: action.payload
    }),
    setMusicSound: (state, action) => ({
      ...state,
      gameMusic: action.payload
    }),
    setAllcurrency: (state, action) => ({
      ...state,
      allCurrencies: action.payload
    }),
    setGameSettings: (state, action) => ({
      ...state,
      gameSettings: action.payload
    }),
    setGameSettingLoader: (state, action) => ({
      ...state,
      setGameSettingLoader: action.payload
    }),
    setShowLoginPopup: (state, action) => ({
      ...state,
      showLoginPopup: action.payload
    }),
    setShowSignupPopup: (state, action) => ({
      ...state,
      showSignupPopup: action.payload
    }),
    setAffilateCode: (state, action) => ({
      ...state,
      affilateCode: action.payload
    }),
    setNewSeed: (state, action) => ({
      ...state,
      clientSeed: action.payload
    }),
    setIsDemoGame: (state, action) => ({
      ...state,
      isDemoGame: action.payload
    }),
    setDemoWallet: (state, action) => ({
      ...state,
      demoWallet: action.payload
    }),
    setGameWiseSounds: (state, action) => ({
      ...state,
      gameWiseSounds: { ...state.gameWiseSounds, [action.payload.game]: action.payload.volume }
    })
  },
  extraReducers: (builder) => {
    builder

      .addCase(getGameSetting.fulfilled, (state, action) => {
        return {
          ...state,
          systemGameData: action.payload,
          gameSettingLoader: false
        }
      })
      .addCase(getWonderGirlsImages.fulfilled, (state, action) => {
        return {
          ...state,
          allWonderGirls: action.payload?.wonderGirls || action.payload,
          gameSettingLoader: false
        }
      })
      .addCase(getAllCurrency.fulfilled, (state, action) => {
        return {
          ...state,
          allCurrencies: action.payload
        }
      })

      .addCase(getGameImages.fulfilled, (state, action) => {
        return {
          ...state,
          allGameImages: action.payload.gameImages || action.payload,
          gameSettingLoader: false
        }
      })
      .addCase(getGameThemeSetting.fulfilled, (state, action) => {
        return {
          ...state,
          gameThemeSetting: action.payload
        }
      })
      .addCase(getEncourageWordsSetting.fulfilled, (state, action) => {
        return {
          ...state,
          encourageSettings: action.payload
        }
      })

      .addCase(getBanner.fulfilled, (state, action) => {
        return {
          ...state,
          homeBanner: action.payload
        }
      })
      .addCase(getAllSiteinfo.fulfilled, (state, action) => {
        return {
          ...state,
          siteInfo: action.payload?.cms
        }
      })

    // .addCase(getBanner.pending, (state, action) => {
    //   return {
    //     ...state,
    //     // homeBanner: action.payload
    //   }
    // })
    // .addCase(getBanner.rejected, (state, action) => {
    //   return {
    //     ...state,
    //     // homeBanner: action.payload
    //   }
    // })

      .addCase(getSponser.fulfilled, (state, action) => {
        return {
          ...state,
          banner: action.payload,
          sponserBannerLoading: true
        }
      })
      .addCase(getSponser.pending, (state, action) => {
        return {
          ...state,
          sponserBannerLoading: true
        }
      })
      .addCase(getSponser.rejected, (state, action) => {
        return {
          ...state,
          sponserBannerLoading: false
        }
      })
      .addCase(getGameIframe.fulfilled, (state, action) => {
        return {
          ...state,
          gameIframeLink: action.payload
        }
      })
  }
})

export default reducer
export {
  setAllcurrency,
  setSystemData,
  setGameSettings,
  setGameSettingLoader,
  setGameSound,
  setMusicSound,
  setShowLoginPopup,
  setShowSignupPopup,
  setAffilateCode,
  setNewSeed,
  setIsDemoGame,
  setDemoWallet,
  setGameWiseSounds
}
