const basePath = '/pixiGameAssets/roulette/assets/'

export const path = {
  imagePath: basePath + 'images/',
  spriteSheetsPath: basePath + 'spritesheets/'
}

export const settings = {
  originalWidth: 1920,
  originalHeight: 1080,

  rouletteWheelSpeed: 0.7,
  rouletteBallSpeed: 0.7,

  color: {
    odd: 0xFF013C,
    even: 0x2F4553,
    neutral: 0x419E3F
  },

  padding: 2,

  normalButtonSize: { //  Button 1 - 36
    width: 50,
    height: 50
  },

  proximaNovaLight: {
    fontFamily: 'Proxima-Nova',
    fontSize: 100,
    fill: '#FFFFFF',
    strokeThickness: 0,
    wordWrap: true,
    wordWrapWidth: 440
  }

}
