import { createAsyncThunk } from '@reduxjs/toolkit'
import { CrashCheckFairness, allRoundPlacedBets, cancelCrashBetService, escapeCrashBetService, getAllBetDataService, getAllRoundHistoryService, getCrashGameRoundDetailService, getHighrollerCrashService, getMyBetsService, getTotalWinningAmountService, placeBetCrashGameService } from '../../../../network/services/auth.service'
import { setPlacedCrashBetId } from '../../../redux-slices/Games/crashGame'

export const placedBetCrashGame = createAsyncThunk('crash-game/place-bet-crash-game', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await placeBetCrashGameService(payload)
    thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    addToast(('Bet Placed  successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const escapeCrashBet = createAsyncThunk('crash-game/player-escape-crashGame', async ({ addToast }, thunkApi) => {
  try {
    const res = await escapeCrashBetService()
    addToast(('Player escaped  successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const cancelCrashBet = createAsyncThunk('crash-game/cancel-bet-crash-game', async ({ addToast }, thunkApi) => {
  try {
    const res = await cancelCrashBetService()
    addToast(('Bet cancelled  successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getAllBetData = createAsyncThunk('crash-game/get-crash-game-bets-data', async (_, thunkApi) => {
  try {
    const res = await getAllBetDataService()
    return res
  } catch (error) {
    // addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getTotalWinningAmount = createAsyncThunk('crash-game/get-winning-amount-data', async (_, thunkApi) => {
  try {
    const res = await getTotalWinningAmountService()
    return res
  } catch (error) {
    // addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getAllRoundHistory = createAsyncThunk('crash-game/get-crash-game-history', async ({ addToast, offset, limit }, thunkApi) => {
  try {
    const res = await getAllRoundHistoryService({ offset, limit })
    return res
  } catch (error) {
    console.log('error', error)
    // addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getCrashGameRoundDetails = createAsyncThunk('crash-game/get-crash-game-round-detail', async ({ addToast }, thunkApi) => {
  try {
    const res = await getCrashGameRoundDetailService()

    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBets = createAsyncThunk('crash-game/myBets', async (_, thunkApi) => {
  try {
    const res = await getMyBetsService()
    return res
  } catch (error) {
    // addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const checkFairnessCrashGame = createAsyncThunk('crash-game/check-fairness', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await CrashCheckFairness(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getAllbetOfOneRound = createAsyncThunk('crash-game/get-all-bets-of-round', async ({ params, addToast }, thunkApi) => {
  try {
    const res = await allRoundPlacedBets({ params })
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getCrashHighRollerTransactions = createAsyncThunk(
  'crash-game/high-roller',
  async (data, thunkApi) => {
    try {
      const res = await getHighrollerCrashService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
