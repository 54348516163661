// import { resetGame, start, stop } from './gameManager'

import { resetGame, start, stop } from './gameManager'

export const pixiStartRoulette = () => {
  start()
}

export const pixiStopRoulette = (stopNumber, onBallStoppedAfterSpin) => {
  stop(stopNumber, onBallStoppedAfterSpin)
}

export const pixiResetRoulette = () => {
  resetGame()
}
