import React from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { forgetPassword } from '../../redux-store/redux/thunk/Auth/Auth'
import GamePopup from '../../components/GamePopup'
import ForgetPasswordContent from '../../components/GamePopupContent/ForgetPasswordContent'
import { closeBootStrapModal } from '../../utils/helper'

const ForgetPassword = () => {
  const { t } = useTranslation('forgetPassword')

  const { addToast } = useToasts()
  const { loading } = useSelector(state => state.user)

  const dispatch = useDispatch()

  const onForgetPasswordSuccess = () => {
    closeBootStrapModal('#forgotPasswordModal')
  }

  const handleForgetPasswordSubmit = async ForgetPasswordDetails => {
    const { userNameOrEmail } = ForgetPasswordDetails
    if (userNameOrEmail) {
      dispatch(forgetPassword({ userNameOrEmail, addToast, onForgetPasswordSuccess }))
    } else {
      addToast(t('resetPassword.wentWrong'), { appearance: 'error' })
    }
  }

  return (
    <GamePopup id='forgotPasswordModal' title={t('forgetPassTitle')}>
      <ForgetPasswordContent handleForgetPasswordSubmit={handleForgetPasswordSubmit} loading={loading} id='forgotPasswordModal' />
    </GamePopup>
  )
}

export default ForgetPassword
