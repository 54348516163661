import { lazy } from 'react'
import { ROUTE_PATHS } from '../utils/constants'
import SettingsSection from '../containers/SettingsSection'
import TransactionsSection from '../containers/TransactionsSection'
import HomeCasinoGamePlay from '../containers/Home/HomeCasinoGamePlay'
import FavouritesGamesSection from '../containers/FavouritesGames'
import RecentlyPlayedGamesSection from '../containers/RecentlyPlayedGame'
import KycVerification from '../containers/KycVerification'
import Partnership from '../containers/Home/Partnership'
import PartnerPrograms from '../containers/PartnerProgram'
const BlackjackGame = lazy(() =>
  import('../containers/AHOriginalsGames/Blackjack/BlackjackGame')
)
const RouletteGame = lazy(() =>
  import('../containers/AHOriginalsGames/Roulette/RouletteGame')
)
const LandingPageContainer = lazy(() =>
  import('../containers/LandingPageContainer')
)
const BetHistory = lazy(() =>
  import('../containers/ProfileContentWrapper/ContentPage/BetHistoryContainer')
)
const ChangePasswordSection = lazy(() =>
  import(
    '../containers/ProfileContentWrapper/ContentPage/ChangePasswordSection'
  )
)
const DepositSection = lazy(() =>
  import('../containers/ProfileContentWrapper/ContentPage/DepositSection')
)
const GameLimitSection = lazy(() =>
  import('../containers/ProfileContentWrapper/ContentPage/GameLimitSection')
)
const ProfileDetails = lazy(() =>
  import('../containers/ProfileContentWrapper/ContentPage/ProfileDetails')
)
const ReferralSection = lazy(() =>
  import('../containers/ProfileContentWrapper/ContentPage/ReferralSection')
)
const TransactionSection = lazy(() =>
  import('../containers/ProfileContentWrapper/ContentPage/TransactionSection')
)
const WithdrawSection = lazy(() =>
  import('../containers/ProfileContentWrapper/ContentPage/WithdrawSection')
)
const CrashGame = lazy(() =>
  import('../containers/AHOriginalsGames/CrashGame/NewCrashGame')
)
const DiceGame = lazy(() =>
  import('../containers/AHOriginalsGames/Dice/DiceGame')
)
const ProfileWrapper = lazy(() =>
  import('../containers/ProfileContentWrapper/index')
)
const SiteInfo = lazy(() => import('../containers/SiteInfo'))
const Home = lazy(() => import('../containers/Home'))
const CasinoLandingPage = lazy(() => import('../containers/Casino'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const UserFeedBackPage = lazy(() => import('../containers/UserFeedBack'))

const routesList = [
  {
    path: ROUTE_PATHS.HOME,
    component: LandingPageContainer,
    child: ResetPassword,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.HOME_SIGNUP,
    component: LandingPageContainer,
    child: ResetPassword,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.LOGIN,
    showHeader: true,
    showFooter: true,
    component: Home
  },
  {
    path: ROUTE_PATHS.SIGNUP,
    showHeader: true,
    showFooter: true,
    component: Home
  },
  {
    path: ROUTE_PATHS.CRASH_GAME,
    component: CrashGame,
    isPrivate: false,
    showHeader: true,
    showFooter: false
  },
  {
    path: ROUTE_PATHS.DICE_GAME,
    component: DiceGame,
    // isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PROFILE.ROOT,
    component: ProfileWrapper,
    child: ProfileDetails,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.PROFILE.BET_HISTORY,
    component: ProfileWrapper,
    child: BetHistory,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.PROFILE.TRANSACTIONS,
    component: ProfileWrapper,
    child: TransactionSection,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.PROFILE.DEPOSIT,
    component: ProfileWrapper,
    child: DepositSection,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.PROFILE.CASHOUT,
    component: ProfileWrapper,
    child: WithdrawSection,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.PROFILE.GAME_LIMITS,
    component: ProfileWrapper,
    child: GameLimitSection,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.PROFILE.REFERRAL,
    component: ProfileWrapper,
    child: ReferralSection,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.PROFILE.CHANGE_PASSWORD,
    component: ProfileWrapper,
    child: ChangePasswordSection,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.VERIFY_EMAIL,
    component: Home,
    // child: Home,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    component: Home,
    child: ResetPassword,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.CASINO_PLAYGAME,
    component: HomeCasinoGamePlay,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.CASINO,
    component: CasinoLandingPage,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.FAV_CATAGORY,
    component: FavouritesGamesSection,
    showHeader: true,
    showFooter: true,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.RECENTLY_PLAYED,
    component: RecentlyPlayedGamesSection,
    showHeader: true,
    showFooter: true,
    isPrivate: true
  },
  {
    path: ROUTE_PATHS.FEEDBACK,
    component: UserFeedBackPage,
    showHeader: true,
    // isPrivate: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.SUBMITFEEDBACK,
    component: PartnerPrograms,
    showHeader: true,
    isPrivate: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PARTNERPROGRAM,
    component: Partnership,
    showHeader: true,
    showFooter: true
    // isPrivate: true
  },
  {
    path: ROUTE_PATHS.PARTNER_TYPE,
    component: PartnerPrograms,
    showHeader: true,
    showFooter: true,
    isPrivate: true
  },
  // {
  //   path: ROUTE_PATHS.ABOUT,
  //   component: About
  // },
  {
    path: ROUTE_PATHS.SITE_INFO,
    component: SiteInfo
  },
  // {
  //   path: ROUTE_PATHS.TERMS_AND_CONDITION,
  //   component: TermsCondition
  // },
  {
    path: ROUTE_PATHS.SETTINGS,
    component: SettingsSection,
    // isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.TRANSACTIONS,
    component: TransactionsSection,
    // isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.BLACKJACKGAME,
    component: BlackjackGame,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.ROULETTE,
    component: RouletteGame,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.KYC,
    component: KycVerification,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  }
]

export default routesList
