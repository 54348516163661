import { METHOD_TYPES } from '../../utils/constants'
import axiosInstanceService, { microServices } from '../apis'

export const placeBetRouletteGameService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'roulette-game/place-bet', data, {
    server: microServices.USER_URL
  })
}

export const getMyBetsRouletteService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'roulette-game/my-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}

export const getRouletteService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'roulette-game/all-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}

export const getHighrollerRouletteService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'roulette-game/top-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}

export const rouletteCheckFairness = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'roulette-game/check-fairness', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}
