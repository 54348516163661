import { createSlice } from '@reduxjs/toolkit'
import { getSumsubToken } from '../redux/thunk/sumsub.thunk'

const initialState = {
  sumsubTokens: null
}

const {
  actions: { setSumsubToken },
  reducer
} = createSlice({
  name: 'sumsub',
  initialState,
  reducers: {
    setSumsubToken: (state, action) => ({
      ...state,
      sumsubTokens: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSumsubToken.fulfilled, (state, action) => {
        return {
          ...state,
          sumsubTokens: action?.payload
        }
      })
  }
})

export { setSumsubToken }
export default reducer
