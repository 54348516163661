import React from 'react'
import { CloseXIcon } from '../../icons'

const GamePopup = ({ id, handleClose, title, children, className }) => {
  return (
    <div className={`modal fade ${className}`} id={id} aria-hidden='true'>
      <div className='modal-dialog modal-fullscreen-sm-down modal-dialog-scrollable'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>{title}</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={handleClose}>
              <CloseXIcon />
            </button>
          </div>
          <div className='modal-body'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GamePopup
