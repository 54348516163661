import React from 'react'
import { Deposit } from './components/Deposit'
import { Withdraw } from './components/Withdraw'

const Wallet = ({ setShowWalletPopup }) => {
  return (
    <div className='modal fade show' id='wallet-modal' style={{ display: 'block' }}>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header py-3 border-0 px-4'>
            <h5 className='modal-title fs-5 text-white ms-2'>Wallet</h5>
            <button
              type='button'
              className='btn-close btn-close-white small'
              onClick={() => {
                setShowWalletPopup(false)
              }}
            />
          </div>
          <div className='modal-body'>
            <ul className='nav nav-pills mb-3 d-flex mx-auto' id='pills-tab' role='tablist'>
              <li className='nav-item' role='presentation'>
                <button className='nav-link active' id='pills-home-tab' data-bs-toggle='pill' data-bs-target='#pills-deposit' type='button' role='tab' aria-controls='pills-home' aria-selected='true'>Deposit</button>
              </li>
              <li className='nav-item' role='presentation'>
                <button className='nav-link' id='pills-contact-tab' data-bs-toggle='pill' data-bs-target='#pills-withdraw' type='button' role='tab' aria-controls='pills-contact' aria-selected='false'>Withdraw</button>
              </li>
            </ul>
            <div className='tab-content' id='pills-tabContent'>
              <Deposit setShowWalletPopup={setShowWalletPopup} />
              <Withdraw setShowWalletPopup={setShowWalletPopup} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wallet
