import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { setOn, setDo, setStrategy, setType, setStrategyId, setBlockOrder, removeBlock } from '../../../../../../../redux-store/redux-slices/strategy'

export const CONDITIONS = {
  EVERY: 'every',
  EVERY_STREAK_OF: 'everyStreakOf',
  FIRST_STREAK_OF: 'firstStreakOf',
  STREAK_GREATER_THAN: 'streakGreaterThan',
  STREAK_LOWER_THAN: 'streakLowerThan',
  BET: 'bet',
  WIN: 'win',
  LOSE: 'lose',
  INCREASE_BY_PERCENTAGE: 'increaseByPercentage',
  DECREASE_BY_PERCENTAGE: 'decreaseByPercentage',
  INCREASE_WIN_CHANCE_BY: 'increaseWinChanceBy',
  DECREASE_WIN_CHANCE_BY: 'decreaseWinChanceBy',
  ADD_TO_AMOUNT: 'addToAmount',
  SUBTRACT_FROM_AMOUNT: 'subtractFromAmount',
  ADD_TO_WIN_CHANCE: 'addToWinChance',
  SUBTRACT_FROM_WIN_CHANCE: 'subtractFromWinChance',
  SET_AMOUNT: 'setAmount',
  SET_WIN_CHANCE: 'setWinChance',
  SWITCH_OVER_UNDER: 'switchOverUnder',
  RESET_AMOUNT: 'resetAmount',
  RESET_WIN_CHANCE: 'resetWinChance',
  STOP: 'stop',
  LOSS: 'loss',
  BALANCE: 'balance',
  PROFIT: 'profit',
  GREATER_THAN: 'greaterThan',
  GREATER_THAN_OR_EQUAL_TO: 'greaterThanOrEqualTo',
  LOWER_THAN: 'lowerThan',
  LOWER_THAN_OR_EQUAL_TO: 'lowerThanOrEqualTo'
}

const LABELS = {
  every: 'Every',
  everyStreakOf: 'Every streak of',
  firstStreakOf: 'First streak of',
  streakGreaterThan: 'Streak greater than',
  streakLowerThan: 'Streak lower than',
  bet: 'Bets',
  win: 'Wins',
  lose: 'Losses',
  increaseByPercentage: 'Increase bet amount',
  decreaseByPercentage: 'Decrease bet amount',
  increaseWinChanceBy: 'Increase win chance',
  decreaseWinChanceBy: 'Decrease win chance',
  addToAmount: 'Add to bet amount',
  subtractFromAmount: 'Subtract from bet amount',
  addToWinChance: 'Add to win chance',
  subtractFromWinChance: 'Subtract from win chance',
  setAmount: 'Set bet amount',
  setWinChance: 'Set win chance',
  switchOverUnder: 'Switch over under',
  resetAmount: 'Reset bet amount',
  resetWinChance: 'Reset win chance',
  stop: 'Stop autobet',
  loss: 'Loss',
  balance: 'Balance',
  profit: 'Profit',
  greaterThan: 'Greater than',
  greaterThanOrEqualTo: 'Greater than or equal to',
  lowerThan: 'Lower than',
  lowerThanOrEqualTo: 'Lower than or equal to'
}

export const profitConditionsOptions = {
  onType: [
    { label: LABELS.balance, id: uuidv4(), value: CONDITIONS.BALANCE },
    { label: LABELS.profit, id: uuidv4(), value: CONDITIONS.PROFIT },
    { label: LABELS.loss, id: uuidv4(), value: CONDITIONS.LOSS }
  ],
  onCondition: [
    { label: LABELS.greaterThan, id: uuidv4(), value: CONDITIONS.GREATER_THAN },
    { label: LABELS.greaterThanOrEqualTo, id: uuidv4(), value: CONDITIONS.GREATER_THAN_OR_EQUAL_TO },
    { label: LABELS.lowerThan, id: uuidv4(), value: CONDITIONS.LOWER_THAN },
    { label: LABELS.lowerThanOrEqualTo, id: uuidv4(), value: CONDITIONS.LOWER_THAN_OR_EQUAL_TO }

  ]

}

export const betConditionsOptions = {
  onType: [
    { label: LABELS.every, id: uuidv4(), value: CONDITIONS.EVERY },
    { label: LABELS.everyStreakOf, id: uuidv4(), value: CONDITIONS.EVERY_STREAK_OF },
    { label: LABELS.firstStreakOf, id: uuidv4(), value: CONDITIONS.FIRST_STREAK_OF },
    { label: LABELS.streakGreaterThan, id: uuidv4(), value: CONDITIONS.STREAK_GREATER_THAN },
    { label: LABELS.streakLowerThan, id: uuidv4(), value: CONDITIONS.STREAK_LOWER_THAN }
  ],
  onBetType: [
    { label: LABELS.bet, id: uuidv4(), value: CONDITIONS.BET },
    { label: LABELS.win, id: uuidv4(), value: CONDITIONS.WIN },
    { label: LABELS.lose, id: uuidv4(), value: CONDITIONS.LOSE }
  ],
  doType: [
    { label: LABELS.increaseByPercentage, id: uuidv4(), value: CONDITIONS.INCREASE_BY_PERCENTAGE },
    { label: LABELS.decreaseByPercentage, id: uuidv4(), value: CONDITIONS.DECREASE_BY_PERCENTAGE },
    { label: LABELS.increaseWinChanceBy, id: uuidv4(), value: CONDITIONS.INCREASE_WIN_CHANCE_BY },
    { label: LABELS.decreaseWinChanceBy, id: uuidv4(), value: CONDITIONS.DECREASE_WIN_CHANCE_BY },
    { label: LABELS.addToAmount, id: uuidv4(), value: CONDITIONS.ADD_TO_AMOUNT },
    { label: LABELS.subtractFromAmount, id: uuidv4(), value: CONDITIONS.SUBTRACT_FROM_AMOUNT },
    { label: LABELS.addToWinChance, id: uuidv4(), value: CONDITIONS.ADD_TO_WIN_CHANCE },
    { label: LABELS.subtractFromWinChance, id: uuidv4(), value: CONDITIONS.SUBTRACT_FROM_WIN_CHANCE },
    { label: LABELS.setAmount, id: uuidv4(), value: CONDITIONS.SET_AMOUNT },
    { label: LABELS.setWinChance, id: uuidv4(), value: CONDITIONS.SET_WIN_CHANCE },
    { label: LABELS.switchOverUnder, id: uuidv4(), value: CONDITIONS.SWITCH_OVER_UNDER },
    { label: LABELS.resetAmount, id: uuidv4(), value: CONDITIONS.RESET_AMOUNT },
    { label: LABELS.resetWinChance, id: uuidv4(), value: CONDITIONS.RESET_WIN_CHANCE },
    { label: LABELS.stop, id: uuidv4(), value: CONDITIONS.STOP }
  ]
}
export const BET_TYPE = {
  BETS_CONDITION: 'bets',
  PROFIT_CONDITION: 'profit'
}
const restrictInput = [CONDITIONS.SWITCH_OVER_UNDER, CONDITIONS.RESET_AMOUNT, CONDITIONS.RESET_WIN_CHANCE, CONDITIONS.STOP]

const percentageInputs = [CONDITIONS.INCREASE_BY_PERCENTAGE, CONDITIONS.DECREASE_BY_PERCENTAGE, CONDITIONS.INCREASE_WIN_CHANCE_BY, CONDITIONS.DECREASE_WIN_CHANCE_BY, CONDITIONS.ADD_TO_WIN_CHANCE, CONDITIONS.SUBTRACT_FROM_WIN_CHANCE, CONDITIONS.SET_WIN_CHANCE]

const BetConditionModal = (props) => {
  const {
    // showBetConditionModal,
    setShowBetConditionModal
  } = props
  const dispatch = useDispatch()
  const { t } = useTranslation('diceGame')
  const { strategies, selectedStrategy, strategyId } = useSelector(state => state.strategy)
  const [strategyDiscription] = strategies?.filter(strategy => strategy?.label === selectedStrategy)?.[0]?.blocks?.filter(block => block?.id === strategyId)

  const handleAddStrategyBlock = () => {
    const block = {
      id: uuidv4(),
      type: 'bets',
      on: {
        type: 'every',
        value: 0,
        betType: 'lose',
        profitType: 'profit'
      },
      do: {
        type: 'increaseByPercentage',
        value: 0
      }
    }
    dispatch(setStrategy(block))
  }

  return (
    <>
      <div
        className='modal fade show'
        style={{ display: 'block' }}
        id='dice_condition_modal01'
        onClick={() => setShowBetConditionModal(false)}
      >
        <div className='modal-dialog dice-modal-lg modal-dialog-centered'>
          <div
            className='modal-content rounded-10'
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className='modal-header py-3 border-0 px-4'>
              <h5 className='modal-title fs-5 text-white ms-2'><img src='/assets/images/dice/new/equalizer-control.png' alt='Img' className='me-2 mb-1' />{t('betConditions.title')}</h5>
              <button
                type='button' className='btn-close btn-close-white small'
                onClick={() => setShowBetConditionModal(false)}
              />
            </div>
            <div className='modal-body'>
              <div className='mb-4 text-white px-4'>
                <h5 className='mb-0 text-center'>{selectedStrategy}</h5>
              </div>
              {strategies?.map(strategy => {
                if (strategy?.label === selectedStrategy) {
                  return strategy?.blocks?.map((block, idx) => block?.id !== strategyId
                    ? (
                      <div key={block?.id} className='condition-section px-md-4'>
                        <div className='condition-heading mb-2'>{t('betConditions.condition')} {idx + 1}</div>
                        <div className='condition-box mb-4 p-0'>
                          <div className='row gx-2'>
                            <div className='col'>
                              <div className='py-1 px-3 mininize-condition-box'>
                                <p
                                  className='text-white mb-0'
                                >
                                  {`On ${LABELS[block?.on?.type]} ${block?.on?.value}  
                                ${block?.type === BET_TYPE.BETS_CONDITION ? LABELS[block?.on?.betType] : LABELS[block?.on?.profitType]}`}
                                </p>
                                <div className='text-white'><img src='/assets/images/dice/new/right-arrow.png' alt='Img' className='me-2 ' /> {LABELS[block?.do?.type]} <span className='text-muted'>{block?.do?.value}</span></div>
                              </div>
                            </div>
                            <div className='col-auto align-self-center'>
                              <ul className='list-inline mb-0 condition-action-menu d-flex'>
                                {idx !== 0 &&
                                  <li
                                    onClick={() => {
                                      dispatch(setBlockOrder({ idx1: idx, idx2: --idx }))
                                    }}
                                    className='list-inline-item text-white px-2 py-1 me-0 border-end pt-2'
                                  >
                                    <i className='fa fa-chevron-up fs-5' />
                                  </li>}
                                {(idx !== (strategy?.blocks?.length - 1)) &&
                                  <li
                                    className='list-inline-item text-white px-2 py-1 me-0 border-end pt-2'
                                    onClick={() => {
                                      dispatch(setBlockOrder({ idx1: idx, idx2: ++idx }))
                                    }}
                                  >
                                    <i className='fa fa-chevron-down fs-5' />
                                    {/* <img src='/assets/images/common/arrow-down-white.svg' alt='Img' /> */}
                                  </li>}
                                <li
                                  onClick={() => {
                                    dispatch(setStrategyId(block?.id))
                                  }}
                                  className='list-inline-item text-white px-2 py-1 me-0 border-end'
                                ><img src='/assets/images/dice/new/edit.png' alt='Img' />
                                </li>
                                <li
                                  onClick={() => {
                                    dispatch(removeBlock(block?.id))
                                  }}
                                  className='list-inline-item text-white px-2 py-1'
                                >
                                  <img src='/assets/images/dice/new/trash.png' alt='Img' />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                    : (
                      <div key={block?.id} className='condition-section px-md-4'>
                        <div className='condition-heading mb-2'>{t('betConditions.condition')} {idx + 1}</div>
                        <div className='condition-box mb-4'>
                          <div className='form-check condition-checkboxs d-inline-block ps-0 pe-5'>
                            <input
                              onChange={() => {
                                // dispatch(setStrategyCondition(BET_TYPE.BETS_CONDITION))
                                dispatch(setType(BET_TYPE.BETS_CONDITION))
                              }}
                              className='form-check-input float-end'
                              type='radio'
                              name='flexRadioDefault'
                              id='flexRadioDefault1'
                              value={BET_TYPE.BETS_CONDITION}
                              checked={strategyDiscription?.type === BET_TYPE.BETS_CONDITION}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault1'>
                              {t('betConditions.betCondition')}
                            </label>
                          </div>
                          <div className='form-check condition-checkboxs d-inline-block ps-0 pe-5 mb-4'>
                            <input
                              onChange={() => {
                                // dispatch(setStrategyCondition(BET_TYPE.PROFIT_CONDITION))
                                dispatch(setType(BET_TYPE.PROFIT_CONDITION))
                              }}
                              className='form-check-input float-end'
                              type='radio'
                              name='flexRadioDefault'
                              id='flexRadioDefault2'
                              value={BET_TYPE.PROFIT_CONDITION}
                              checked={strategyDiscription?.type === BET_TYPE.PROFIT_CONDITION}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault2'>
                              {t('betConditions.profitCondition')}
                            </label>
                          </div>
                          {/* input box column start */}
                          <div className='row gx-3 px-md-4'>
                            <div className='col-12'>
                              <label className='text-white fw-bold px-1'>{t('betConditions.on')}</label>
                            </div>
                            {strategyDiscription?.type === BET_TYPE.BETS_CONDITION &&
                              <>
                                <div className='col-md-4 mb-3'>
                                  <select
                                    className='form-control'
                                    id='strategy_select_box'
                                    value={strategyDiscription?.on.type}
                                    onChange={(e) => {
                                      dispatch(setOn({ type: e.target.value }))
                                    }}
                                  >
                                    {betConditionsOptions.onType.map(condition =>
                                      <option key={condition.id} value={condition.value}>
                                        {condition.label}
                                      </option>
                                    )}
                                  </select>
                                </div>
                                <div className='col-md-4 mb-3'>
                                  <input
                                    placeholder='0'
                                    type='number'
                                    className='form-control'
                                    value={strategyDiscription?.on?.value}
                                    onChange={(e) => {
                                      dispatch(setOn({ value: parseFloat(e.target.value) }))
                                    }}
                                  />
                                </div>
                                <div className='col-md-4 mb-3'>
                                  <select
                                    className='form-control'
                                    id='strategy_select_box'
                                    value={strategyDiscription?.on?.betType}
                                    onChange={(e) => {
                                      dispatch(setOn({ betType: e.target.value }))
                                    }}
                                  >
                                    {betConditionsOptions.onBetType.map(condition =>
                                      <option key={condition.id} value={condition.value}>
                                        {condition.label}
                                      </option>
                                    )}
                                  </select>
                                </div>
                              </>}

                            {strategyDiscription?.type === BET_TYPE.PROFIT_CONDITION &&
                              <>
                                <div className='col-md-4 mb-3'>
                                  <select
                                    className='form-control'
                                    id='strategy_select_box'
                                    value={strategyDiscription?.on?.profitType}
                                    onChange={(e) => {
                                      dispatch(setOn({ profitType: e.target.value }))
                                    }}
                                  >
                                    {profitConditionsOptions?.onType?.map(condition =>
                                      <option key={condition.id} value={condition.value}>
                                        {condition.label}
                                      </option>
                                    )}
                                  </select>
                                </div>

                                <div className='col-md-4 mb-3'>
                                  <select
                                    className='form-control'
                                    id='strategy_select_box'
                                    value={strategyDiscription?.on?.type}
                                    onChange={(e) => {
                                      dispatch(setOn({ type: e.target.value }))
                                    }}
                                  >
                                    {profitConditionsOptions?.onCondition?.map(condition =>
                                      <option key={condition.id} value={condition.value}>
                                        {condition.label}
                                      </option>
                                    )}
                                  </select>
                                </div>
                                <div className='col-md-4 mb-3'>
                                  <input
                                    placeholder='0'
                                    type='number'
                                    className='form-control currency-btc-icon'
                                    value={strategyDiscription?.on?.value}
                                    onChange={(e) => {
                                      dispatch(setOn({ value: parseFloat(e.target.value) }))
                                    }}
                                  />
                                </div>
                              </>}
                            <div className='col-12'>
                              <label className='text-white fw-bold px-1'>{t('betConditions.do')}</label>
                            </div>
                            <div className={`${!restrictInput?.includes(strategyDiscription?.do?.type) ? 'col-md-6' : 'col-md-12'} mb-3`}>
                              <select
                                value={strategyDiscription?.do?.type}
                                onChange={(e) => {
                                  dispatch(setDo({ type: e?.target?.value }))
                                }}
                                className='form-control'
                                id='strategy_select_box'
                              >
                                {betConditionsOptions.doType.map(condition =>
                                  <option key={condition.id} value={condition.value}>
                                    {condition.label}
                                  </option>
                                )}
                              </select>
                            </div>
                            {!restrictInput?.includes(strategyDiscription?.do?.type) &&
                              <div className='col-md-6 mb-3'>
                                <input
                                  type='number'
                                  placeholder='0'
                                  className={`form-control ${percentageInputs?.includes(strategyDiscription?.do?.type) ? 'input-percentage' : 'currency-btc-icon'}`}
                                  value={strategyDiscription?.do?.value}
                                  onChange={(e) => {
                                    dispatch(setDo({ value: parseFloat(e?.target?.value) }))
                                  }}
                                />
                              </div>}
                            <div className='col-12 text-center justify-content-md-end flex-column flex-md-row d-flex'>
                              <button
                                type='button'
                                className='btn btn-sidebar-modal me-sm-2 mb-3 mb-sm-0'
                                onClick={() => {
                                  dispatch(setStrategyId(null))
                                }}
                              >{t('betConditions.minimize')}
                                <img src='/assets/images/dice/new/minimize.png' alt='Img' height='14' className='ms-2 ' />
                              </button>
                              <button
                                type='button'
                                className='btn btn-sidebar-modal'
                                onClick={() => {
                                  dispatch(removeBlock(block?.id))
                                }}
                              >{t('betConditions.delete')}
                                <img src='/assets/images/dice/new/trash.png' alt='Img' className='ms-2 ' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                  )
                }
                return null
              })}
              <div className='condition-section px-md-4'>
                <button type='button' className='btn btn-sidebar-modal py-2 w-100 shadow-none mb-4' onClick={handleAddStrategyBlock}>{t('betConditions.addCondition')}</button>
                <p className='text-white text-center small'>{t('betConditions.disclaimer')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BetConditionModal
