import homeEn from './home.json'
import ProfileEn from './profile.json'
import TransactionEn from './transaction.json'
import crashGameEn from './games/crashGames.json'
import BetHistoryEn from './betHistory.json'
import ForgetPasswordEn from './forgetPassword.json'
import PromotionsEn from './promotions.json'
import diceGameEn from './games/diceGames.json'
import blackjackGameEn from './games/blackjackGames.json'
import jackpotEn from './jackpot.json'
import gameCommonEn from './games/gameCommon.json'
import rouletteGameEn from './games/rouletteGames.json'
import backendMsgEn from './backendMsg.json'
const translationsFR = {
  home: homeEn,
  profile: ProfileEn,
  transaction: TransactionEn,
  crashGame: crashGameEn,
  betHistory: BetHistoryEn,
  forgetPassword: ForgetPasswordEn,
  promotions: PromotionsEn,
  diceGame: diceGameEn,
  jackpot: jackpotEn,
  gameCommon: gameCommonEn,
  blackJackGame: blackjackGameEn,
  rouletteGame: rouletteGameEn,
  backendMsg: backendMsgEn
}

export default translationsFR
