import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_PAGE_CALLS } from '../../../utils/constants'
import { getWinningBetTypes } from '../../../containers/AHOriginalsGames/Roulette/RouletteGame/game-helpers'
import { getMyBetsRoulette, getRouletteHighRollerTransactions, getRouletteTransactions, placedBetRouletteGame } from '../../redux/thunk/Games/rouletteGame'

const initialState = {
  placedBetData: null,
  betLock: false,
  betLoading: false,
  myRouletteBetsData: { count: 0, rows: [] },
  myRouletteBetsLoading: false,
  showWin: false,
  recentWinning: [],
  winTypesContent: null,
  netProfitLoss: 0
}

const {
  actions: {
    setBetLock,
    setRouletteRoundEnd,
    appendMyBetsRoulette,
    resetNetProfitLoss
  },
  reducer
} = createSlice({
  name: 'rouletteGame',
  initialState,
  reducers: {
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: false
      }
    },
    setRouletteRoundEnd: (state, action) => {
      return {
        ...state,
        showWin: true,
        betLock: false,
        recentWinning: [state.placedBetData, ...state.recentWinning].slice(0, 4)
      }
    },
    appendMyBetsRoulette: (state, action) => {
      const { nextServerSeedHash, ...data } = state.placedBetData || { nextServerSeedHash: '', data: {} }
      if (nextServerSeedHash) {
        const myBetsDataRows = [data, ...state.myRouletteBetsData?.rows]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
        }
        return {
          ...state,
          myRouletteBetsData: {
            count: state.myRouletteBetsData?.count + 1,
            rows: myBetsDataRows
          }
        }
      }
      return state
    },
    resetNetProfitLoss: (state, action) => ({
      ...state,
      netProfitLoss: 0
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetRouletteGame.pending, (state, action) => {
        return {
          ...state,
          showWin: false,
          betLock: true,
          betLoading: true,
          winTypesContent: null
        }
      })
      .addCase(placedBetRouletteGame.fulfilled, (state, action) => {
        const { winningNumber, betAmount, winningAmount } = action.payload || { winningNumber: 0 }

        const winTypesContent = getWinningBetTypes(winningNumber)
        const newNetGain = state.netProfitLoss + parseFloat(winningAmount) - parseFloat(betAmount)
        return {
          ...state,
          winTypesContent,
          placedBetData: action.payload,
          betLoading: false,
          netProfitLoss: newNetGain
        }
      })
      .addCase(placedBetRouletteGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false
        }
      })
      .addCase(getMyBetsRoulette.pending, (state, action) => {
        return {
          ...state,
          myRouletteBetsData: action.payload,
          myRouletteBetsLoading: true,
          betsLoading: true
        }
      })
      .addCase(getMyBetsRoulette.fulfilled, (state, action) => {
        return {
          ...state,
          myRouletteBetsData: action.payload,
          myRouletteBetsLoading: false,
          betsLoading: false
        }
      })
      .addCase(getMyBetsRoulette.rejected, (state, action) => {
        return {
          ...state,
          myRouletteBetsLoading: false,
          betsLoading: false
        }
      })
      .addCase(getRouletteTransactions.pending, (state, action) => {
        return {
          ...state,
          betsLoading: true
        }
      })
      .addCase(getRouletteTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          rouletteAllTransactions: action?.payload,
          betsLoading: false
        }
      })
      .addCase(getRouletteTransactions.rejected, (state, action) => {
        return {
          ...state,
          betsLoading: false
        }
      })
      .addCase(getRouletteHighRollerTransactions.pending, (state, action) => {
        return {
          ...state,
          betsLoading: true
        }
      })
      .addCase(getRouletteHighRollerTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          rouletteHighRollerTransactions: action?.payload,
          betsLoading: false
        }
      })
      .addCase(getRouletteHighRollerTransactions.rejected, (state, action) => {
        return {
          ...state,
          betsLoading: false
        }
      })
  }
})

export {
  setBetLock,
  setRouletteRoundEnd,
  appendMyBetsRoulette,
  resetNetProfitLoss
}

export default reducer
