import { createSlice } from '@reduxjs/toolkit'
import { userLogin, userLogout, updatePassword } from '../../redux/thunk/Auth/Auth'
import { getLocalLanguage, setLocalLanguage } from '../../../utils/common-services/localstorage.services'
import { LANGUAGES, LANGUAGE_LIST } from '../../../utils/constants/index'

const defaultAuthState = {
  loginData: null,
  operatorInformationData: null,
  language: getLocalLanguage() || LANGUAGE_LIST[0].code
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    handleLanguageChange: (state, action) => {
      setLocalLanguage(action.payload)
      return {
        ...state,
        language: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        return {
          ...state,
          loginData: action.payload,
          language: action.payload?.additionalInfo?.languageCode || LANGUAGES[0].languageCode
        }
      })

      .addCase(userLogin.pending, (state, action) => {
        const { password, userNameOrEmail } = action.meta.arg
        return {
          ...state,
          loginData: { password, userNameOrEmail }
        }
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        return {
          ...state
        }
      })
  }
})

export const { handleLanguageChange } = authSlice.actions

export default authSlice.reducer
