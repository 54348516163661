import { createAsyncThunk } from '@reduxjs/toolkit'
import { pixiResetRoulette, pixiStartRoulette, pixiStopRoulette } from '../../../../GamePixiLogic/Roulette/scripts/bridge'
import { getHighrollerRouletteService, getMyBetsRouletteService, getRouletteService, placeBetRouletteGameService, rouletteCheckFairness } from '../../../../network/services/rouletteGame.service'
import { delay } from '../../../../utils/helper'
import { setSelectedWallet, setUserData } from '../../../redux-slices/Users/user'

const updateUserBalanceOnWallet = (thunkApi, payload) => {
  const { user, selectedWallet } = thunkApi?.getState()?.user
  const { betDetails } = payload
  const totalBetAmount = betDetails?.reduce(
    (acc, cur) => acc + +cur.amount,
    0
  )
  const updatedWalletData = {
    id: selectedWallet?.id,
    currencyId: selectedWallet?.currencyId,
    amount: +selectedWallet?.amount - totalBetAmount
  }

  console.log('totalBetAmount', updatedWalletData, selectedWallet)
  const userWalletData = user?.wallets
  const updatedUserWalletData = userWalletData?.map(ele => {
    if (ele.id === updatedWalletData.id) {
      return { ...ele, amount: updatedWalletData.amount }
    } else {
      return ele
    }
  })
  // if (selectedWallet?.currencyId === updatedWalletData?.currencyId) {
  thunkApi?.dispatch(setSelectedWallet({
    ...selectedWallet,
    amount: updatedWalletData.amount
  }))
  // }
  thunkApi?.dispatch(setUserData({
    ...user,
    wallets: updatedUserWalletData
  }))
}

export const placedBetRouletteGame = createAsyncThunk('roulette-game/place-bet',
  async ({ payload, onBallStoppedAfterSpin, addToast, generateRandomClientSeed, isAutoBet, startBetAudio }, thunkApi) => {
    try {
      const res = await placeBetRouletteGameService(payload)
      if (res?.winningNumber !== undefined) {
        updateUserBalanceOnWallet(thunkApi, payload)
        if (isAutoBet) addToast('Auto Bet Started', { appearance: 'success' })
        addToast('Bet Place Successfully', { appearance: 'success' })
        startBetAudio.play()
        pixiStartRoulette()
        await delay(1000)
        pixiStopRoulette(res.winningNumber, () => onBallStoppedAfterSpin(res))
        generateRandomClientSeed()
      } else {
        pixiResetRoulette()
      }
      return { ...res, game: { id: '4', name: 'roulette' } }
    } catch (error) {
      pixiResetRoulette()
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBetsRoulette = createAsyncThunk('roulette-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsRouletteService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getRouletteHighRollerTransactions = createAsyncThunk(
  'roulette-game/high-roller',
  async (data, thunkApi) => {
    try {
      const res = await getHighrollerRouletteService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getRouletteTransactions = createAsyncThunk(
  'roulette-game/get-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getRouletteService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const checkFairnessRouletteGame = createAsyncThunk('roulette-game/check-fairness', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await rouletteCheckFairness(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})
