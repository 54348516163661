import React, { lazy, useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import routes from './routesList'
import PrivateRoute from './HOC/PrivateRoutes/index'
import PublicRoutes from './HOC/PublicRoutes/index'
import { getAuthToken } from '../utils/common-services/cookie.services'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SideNavBar from '../components/SideNavBar'
import SideBetHistory from '../containers/SideBetHistory'
import { setShowSideBetList } from '../redux-store/redux-slices/settings'
import DraggablePopup from '../components/GamePopup/DraggablePopup'
import LiveStatsContent from '../components/GamePopupContent/LiveStatsContent'
import { DEFAULT_LIVE_STATS } from '../utils/constants'
import { resetLiveStatsData } from '../redux-store/redux-slices/games'
import { handleLiveStatsVisibilityChange } from '../utils/helper'

// import SideNavBar from '../components/SideNavBar'
const NotFoundComponent = lazy(() => import('../components/404'))

const RouteRenderer = (route, showLandingPage) => {
  const { component: Component, child: Child, showFooter = false } = route
  const isAuthenticated = !!getAuthToken()

  return (
    <>

      {route?.isPrivate
        ? (
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            Component={Component}
            child={Child}
          />
          )
        : (
          <PublicRoutes
            isAuthenticated={isAuthenticated}
            Component={Component}
            child={Child}
          />
          )}
      {(!showLandingPage && showFooter) && <Footer />}
    </>
  )
}

const Wrapper = ({ showLandingPage }) => {
  const { showSideBetList, showSideNav } = useSelector(state => state?.settings)
  const { liveStatsData } = useSelector(state => state.games)
  const { t } = useTranslation('home')
  const hasVisited = localStorage.getItem('hasVisited')
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const myRef = useRef(null)

  useEffect(() => {
    if (hasVisited === null) {
      // Redirect to the landing page if 'hasVisited' is undefined
      history.push('/')
    }
  }, [])
  // Scroll to top if path changes
  useLayoutEffect(() => {
    myRef.current.scrollTo(0, 0)
  }, [myRef.current, location.pathname])

  useEffect(() => {
    dispatch(setShowSideBetList(location.pathname?.split('/')[1] === 'casino' && location.pathname?.split('/')[2] === 'game'))
  }, [location.pathname])

  const resetLiveStats = useCallback(() => {
    dispatch(resetLiveStatsData(DEFAULT_LIVE_STATS))
  }, [])

  return (
    <>
      <div ref={myRef} className={`page-container ${showSideBetList ? 'open-bets' : ''} ${!showSideNav ? 'sidebar-close' : ''}`}>
        <Header showLandingPage={showLandingPage} />
        <Switch>
          {routes.map((route) => (
            <Route
              path={route.path}
              key={route.path}
              exact
              render={() => RouteRenderer(route, showLandingPage)}
            />
          ))}
          <Route component={() => <NotFoundComponent />} />
        </Switch>
      </div>
      {!showLandingPage && (<SideBetHistory />)}
      {/* LIVE STATS MODAL START */}
      <DraggablePopup id='liveStatsModal' title={t('liveStats')} onClose={() => handleLiveStatsVisibilityChange(false)}>
        <LiveStatsContent liveStatsData={liveStatsData} resetLiveStats={resetLiveStats} />
      </DraggablePopup>
    </>
  )
}

const CustomRoutes = () => {
  const { showLandingPage } = useSelector((state) => state.settings)
  const location = useLocation()
  const isCasinoGamePlayPage = location.pathname?.split('/')[2] === 'play-game'
  return (
    <>
      <>
        {(!showLandingPage && !isCasinoGamePlayPage) && (<SideNavBar />)}
        <Wrapper showLandingPage={showLandingPage} />
      </>
    </>
  )
}

export default CustomRoutes
