import './partnerProgram.scss'
import { useParams } from 'react-router-dom'

import PartnerProgramForm from './programForm'
import { PartnerProgramsTabs } from '../../utils/constants'

const PartnerPrograms = () => {
  const programTab = PartnerProgramsTabs[0].id
  const { id } = useParams()

  return (
    <div className='main-container'>
      <section className='container mt-3'>
        {/* <Partnership /> */}

        {/* <div className='tab-selection d-flex justify-content-center flex-wrap'>
          <ul className='nav' id='ex1'>
            {PartnerProgramsTabs?.map((tab) => (
              <li key={tab.id} role='presentation'>
                <a
                  role='tab'
                  className={`selected-tab ${programTab === tab.id ? 'active' : ''
                    }`}
                  onClick={(e) => {
                    setProgramTab(tab.id)
                  }}
                >
                  {tab.name}
                </a>
              </li>
            ))}
          </ul>
        </div> */}
        {id === 'investor'
          ? (
            <div className='d-flex align-items-center justify-content-center gap-4'>
              <span className='before-line' />
              <h3 className='text-white text-center fw-bold my-3 text-uppercase investment-heading'>Become An Affiliate</h3>
              <span className='after-line' />
            </div>
            )
          : (
            <div className='d-flex align-items-center justify-content-center gap-4'>
              <span className='before-line' />
              <h3 className='text-white text-center fw-bold my-3 text-uppercase investment-heading'>
                Become An Early Investor
              </h3>
              <span className='after-line' />
            </div>
            )}
        <div className='tab-content d-flex justify-content-center'>
          {PartnerProgramsTabs.map((tab) => (
            <PartnerProgramForm
              key={tab.id}
              id={tab.id}
              selectedTab={programTab}
              currentTab={tab.id}
              name={tab.name}
            />
          ))}
        </div>
      </section>
    </div>
  )
}

export default PartnerPrograms
