import { createSlice } from '@reduxjs/toolkit'
import { depositAmountRequest } from '../redux/thunk/Users/User'

const initialState = {
  depositData: null,
  loading: false
}
const {
  actions: {
    setDepositData
  },
  reducer
} = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setDepositData: (state, action) => ({
      ...state,
      depositData: null
    })

  },
  extraReducers: (builder) => {
    builder
      .addCase(depositAmountRequest.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(depositAmountRequest.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          depositData: action.payload
        }
      })
      .addCase(depositAmountRequest.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export default reducer
export {
  setDepositData
}
