import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setShowSideNav } from '../../redux-store/redux-slices/settings'
import { getItem, setItem } from '../../utils/common-services/localstorage.services'
import { ROUTE_PATHS } from '../../utils/constants'
import { BlackjackIcon, CasinoIcon, ChallengesIcon, CloseXIcon, EnhancedRTPIcon, FeatureIcon, GiftIcon, HistoryIcon, LiveCasinoIcon, MenuIcon, RocketIcon, RoulletteIcon, SlotsIcon, SportsIcon, StarFillIcon, TableGamesIcon, HomeIcon } from '../../icons' // FireIcon ExclusivesIcon
import LanguageSwitcher from '../Header/LanguageSwitcher'
import './sideNavBar.scss'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import { setShowLoginPopup } from '../../redux-store/redux-slices/gameSetting'

function SideNavbar () {
  const { t } = useTranslation('home')
  const dispatch = useDispatch()
  // const { showSideNav } = useSelector(state => state?.settings)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [isAsideOpen, setIsAsideOpen] = useState(false)
  const asideRef = useRef()
  const history = useHistory()
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  const handleProtectedRoutes = (currentPath) => {
    if (getAuthToken()) {
      history.push(currentPath)
      return
    }
    dispatch(setShowLoginPopup(true))
  }

  // const sidenavExpand = () => {
  //   setItem('showSideNav', !showSideNav)
  //   dispatch(setShowSideNav(!showSideNav))
  //   document.getElementsByClassName('sidebar-wrap')[0]?.classList.toggle('sidebar-close')
  //   document.getElementsByClassName('page-container')[0]?.classList.toggle('sidebar-close')
  //   document.getElementsByClassName('header')[0]?.classList.toggle('sidebar-close')
  // }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside (event) {
      if (asideRef.current && !asideRef.current.contains(event.target) && isAsideOpen) {
        console.log('Hello')
        setIsAsideOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [asideRef, isAsideOpen])

  useEffect(() => {
    dispatch(setShowSideNav(getItem('showSideNav')))
  }, [])

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions)
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (windowWidth <= 768) {
      setItem('showSideNav', false)
      dispatch(setShowSideNav(false))
    }
  }, [windowHeight, windowWidth])

  return (
    <>
      {/* NEW SIDEBAR DESIGN START */}
      <aside ref={asideRef} className={isAsideOpen ? 'sidebar-open' : ''}>
        <div className='btn-wrap'>
          <NavLink to={ROUTE_PATHS.CASINO} className='btn custom-btn' onClick={() => setIsAsideOpen(false)}>
            <span className='icon'>
              <CasinoIcon />
            </span>
            <span className='text'>{t('sideNavBar.tabs.casino')}</span>
          </NavLink>
          <a href='#' className='btn custom-btn' title={t('sideNavBar.comingSoon')}>
            <span className='icon'>
              <SportsIcon />
            </span>
            <span className='text'>{t('sideNavBar.tabs.sports')}</span>
          </a>
          <div className='d-block d-md-none'>
            <LanguageSwitcher />
          </div>
        </div>
        <div className='sidenav-warp'>
          <NavLink to={ROUTE_PATHS.CASINO} className='text-decoration-none' onClick={() => setIsAsideOpen(false)}>
            <h6 className='title-text'>{t('sideNavBar.tabs.casino')}</h6>
          </NavLink>
          <div className='sidenav-list'>
            <NavLink
              to={ROUTE_PATHS.HOME}
              exact
              className='list-item'
              data-placement='top'
              data-toggle='tooltip'
              title='Home'
            >
              <span className='icon'>
                <HomeIcon />
              </span>
              <span className='text'>Home</span>
            </NavLink>

            <NavLink
              to={ROUTE_PATHS.FAV_CATAGORY}
              className='list-item'
              data-placement='top'
              data-toggle='tooltip'
              title={t('sideNavBar.comingSoon')}
            >
              <span className='icon'>
                <StarFillIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.favourites')}</span>
            </NavLink>
            <NavLink
              to={ROUTE_PATHS.RECENTLY_PLAYED}
              className='list-item'
              data-placement='top'
              data-toggle='tooltip'
              title={t('sideNavBar.comingSoon')}
            >
              <span className='icon'>
                <HistoryIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.recent')}</span>
            </NavLink>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <ChallengesIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.challenges')}</span>
            </a>
            {/* <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <FireIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.original')}</span>
            </a> */}
            {/* <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <ExclusivesIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.exclusives')}</span>
            </a> */}
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <SlotsIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.slots')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <LiveCasinoIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.live')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <GiftIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.shows')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <RocketIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.newReleases')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <FeatureIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.buyIn')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <EnhancedRTPIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.rtp')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <TableGamesIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.tables')}</span>
            </a>
            <NavLink to={ROUTE_PATHS.BLACKJACKGAME} className='list-item' data-placement='top' data-toggle='tooltip' title='Blackjack' onClick={() => setIsAsideOpen(false)}>
              <span className='icon'>
                <BlackjackIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.blackjack')}</span>
            </NavLink>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='icon'>
                <CasinoIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.baccarat')}</span>
            </a>
            <NavLink to={ROUTE_PATHS.ROULETTE} className='list-item' data-placement='top' data-toggle='tooltip' title='Roulette' onClick={() => setIsAsideOpen(false)}>
              <span className='icon icon-roullette'>
                <RoulletteIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.roulette')}</span>
            </NavLink>
            <hr className='custom-hr' />

            {/* NOTE: COMMENT THIS FOR TEMPORARY USAGE */}
            {/* <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.sports')}</span>
            </a> */}
            {/* <NavLink to={ROUTE_PATHS.FEEDBACK} className='list-item' data-placement='top' data-toggle='tooltip' title='Feedback' onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.feedback')}</span>
            </NavLink> */}
            <div className='list-item list-item-cursor' data-placement='top' data-toggle='tooltip' title='Feedback' onClick={() => handleProtectedRoutes(ROUTE_PATHS.FEEDBACK)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.feedback')}</span>
            </div>
            <div className='list-item list-item-cursor' data-placement='top' data-toggle='tooltip' title='Partner Program' onClick={() => handleProtectedRoutes(ROUTE_PATHS.PARTNERPROGRAM)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.partnerProgram')}</span>
            </div>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.loyaltyProgram')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.blog')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.liveSupport')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.promotions')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.sponsorships')}</span>
            </a>
          </div>
        </div>
      </aside>
      <div className='sidebar-ovrelay' />
      {/* NEW SIDEBAR DESIGN END */}
      <div className='mobile-bottom-bar d-md-none'>
        <ul className='mobile-nav-wrap mb-0 d-flex justify-content-center'>
          <li className='nav-item flex-0'>
            <span className='nav-link' onClick={() => setIsAsideOpen(!isAsideOpen)}>
              <span className='icon'>
                {isAsideOpen ? <CloseXIcon /> : <MenuIcon />}
              </span>
              <span className='text'>{t('sideNavBar.menu')}</span>
            </span>
          </li>
          {/* <li className='nav-item'>
            <a href='#' className='nav-link'>
              <span className='icon'>
                <SearchIcon />
              </span>
              <span className='text'>Search</span>
            </a>
          </li>
          <li className='nav-item'>
            <a href='#' className='nav-link'>
              <span className='icon'>
                <ChatIcon />
              </span>
              <span className='text'>Chat</span>
            </a>
          </li>
          <li className='nav-item'>
            <a href='#' className='nav-link'>
              <span className='icon'>
                <GameIcon />
              </span>
              <span className='text'>Games</span>
            </a>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export default React.memo(SideNavbar)
