import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { setShowCrashGameHistoryModal } from '../../../../../redux-store/redux-slices/Games/crashGame'
import { getAllRoundHistory } from '../../../../../redux-store/redux/thunk/Games/crashGame'
import { getAuthToken } from '../../../../../utils/common-services/cookie.services'
import { setRoundData } from '../../../../../redux-store/redux-slices/Users/user'
import './AllRoundHistoryModal.scss'
import { useTranslation } from 'react-i18next'
import { CloseXIcon } from '../../../../../icons'
import { moment } from '../../../../../config/moment'

const AllRoundHistory = (props) => {
  const { t } = useTranslation('crashGame')
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [offset, setOffset] = useState(0)
  const isLoggedIn = getAuthToken()
  const LIMIT = 10

  const { allRoundHistoryData } = useSelector(state => state?.crashGame)

  const handleClose = () => {
    dispatch(setShowCrashGameHistoryModal(false))
  }

  const handleNextRecords = () => {
    setOffset(prevState => (prevState + LIMIT))
  }

  const handlePrevRecords = () => {
    setOffset(prevState => (prevState - LIMIT))
  }

  useEffect(() => {
    dispatch(getAllRoundHistory({ addToast, offset: offset.toString(), limit: LIMIT.toString() }))
  }, [offset])

  return (
    <>
      <div
        className='modal fade show'
        style={{ display: 'block' }} id='AllroundHistoryModal'
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div
            className='modal-content overflow-hidden'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='modal-header py-3 border-0 px-4'>
              <h5 className='modal-title fs-5 text-white ms-2 text-start'>{t('crashHistory.title')}</h5>
              <button
                type='button'
                className='btn-close small me-1 mt-0'
                onClick={() => {
                  handleClose()
                }}
              ><CloseXIcon />
              </button>
            </div>
            <div className='modal-body table-wrap mt-0 pt-0'>
              <table className='table table-striped table-default mb-0'>
                <thead>
                  <tr>
                    <th className='text-start'>{t('crashHistory.header.time')}</th>
                    <th>{t('crashHistory.header.multi')}</th>
                    <th>{t('crashHistory.header.detail')}</th>
                  </tr>
                </thead>
                <tbody>
                  {allRoundHistoryData?.rows?.map(data =>
                    <tr key={data.id}>
                      <td>
                        <div className='text-box justify-content-start'>
                          <span className='icon'>
                            {moment.utc(data?.createdAt).format('h:mm A')} {moment.utc(data?.createdAt).format('MM/DD/YYYY')}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='text-box'>
                          <span className='icon'>
                            {data?.crashRate}x
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className='text-box'
                          onClick={() => {
                            if (isLoggedIn) {
                              dispatch(setShowCrashGameHistoryModal(false))
                              dispatch(setRoundData(data))
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <span className='text'>View</span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan='3' className='text-center border-0 pt-4'>
                      <button
                        disabled={offset <= LIMIT}
                        type='button'
                        className='bg-transparent border-0 text-muted me-3 fw-bold'
                        onClick={() => {
                          handlePrevRecords()
                        }}
                      >
                        {t('crashHistory.footer.prev')}
                      </button>
                      <button
                        type='button'
                        disabled={allRoundHistoryData?.count - LIMIT < offset}
                        className='bg-transparent border-0 fw-bold text-muted'
                        onClick={() => {
                          handleNextRecords()
                        }}
                      >
                        {t('crashHistory.footer.next')}
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllRoundHistory
