import { createSlice } from '@reduxjs/toolkit'
import { escapeCrashBet, getAllBetData, getAllRoundHistory, getAllbetOfOneRound, getCrashGameRoundDetails, getCrashHighRollerTransactions, getMyBets, getTotalWinningAmount } from '../../redux/thunk/Games/crashGame'

const initialState = {
  loading: false,
  roundBetResult: null,
  betHistoryData: [],
  roundBetHistory: null,
  resultData: [],
  diceGameBetHistoryData: [],
  currentRoundId: '',
  placedCrashBetId: '',
  allRoundHistoryData: [],
  crashGameRoundDetailsData: [],
  crashGameRoundDetailsLoading: false,
  allRoundloading: false,
  myBetsData: [],
  myBetsLoading: false,
  betTopHistoryData: [],
  totalWinningAmount: null,
  totalBets: null,
  showCrashGameHistoryModal: false,
  selectedRoundHashForFairness: '',
  allBetsOneRound: null,
  crashHighRollerTransactions: null
}

const {
  actions: {
    setRoundBetResult,
    setPlacedBetsData,
    setTopBetsData,
    setResultData,
    setDiceGameBetHistoryData,
    setCurrentRoundId,
    setPlacedCrashBetId,
    setShowCrashGameHistoryModal,
    setSelectedRoundHashForFairness
  },
  reducer
} = createSlice({
  name: 'crashGame',
  initialState,
  reducers: {
    setRoundBetResult: (state, { payload }) => ({
      ...state,
      roundBetResult: payload
    }),
    setPlacedBetsData: (state, action) => {
      return {
        ...state,
        betHistoryData: action.payload
      }
    },
    setTopBetsData: (state, action) => {
      return {
        ...state,
        betTopHistoryData: action.payload
      }
    },

    setResultData: (state, action) => {
      return {
        ...state,
        resultData: action.payload
      }
    },
    setDiceGameBetHistoryData: (state, action) => {
      return {
        ...state,
        diceGameBetHistoryData: action.payload
      }
    },
    setCurrentRoundId: (state, action) => {
      return {
        ...state,
        currentRoundId: action.payload
      }
    },
    setPlacedCrashBetId: (state, action) => {
      return {
        ...state,
        placedCrashBetId: action.payload
      }
    },
    setShowCrashGameHistoryModal: (state, action) => {
      return {
        ...state,
        showCrashGameHistoryModal: action.payload
      }
    },
    setSelectedRoundHashForFairness: (state, action) => {
      return {
        ...state,
        selectedRoundHashForFairness: action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(escapeCrashBet.fulfilled, (state, { payload }) => ({
        ...state,
        roundBetResult: payload
      }))
      .addCase(getAllRoundHistory.fulfilled, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: action.payload,
          allRoundloading: false
        }
      })
      .addCase(getTotalWinningAmount.fulfilled, (state, action) => {
        return {
          ...state,
          totalWinningAmount: action.payload
        }
      })
      .addCase(getAllBetData.fulfilled, (state, action) => {
        return {
          ...state,
          totalBets: action.payload
        }
      })
      .addCase(getAllbetOfOneRound.fulfilled, (state, action) => {
        return {
          ...state,
          allBetsOneRound: action.payload
        }
      })

      .addCase(getAllRoundHistory.rejected, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: [],
          allRoundloading: false
        }
      })

      .addCase(getAllRoundHistory.pending, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: { ...state.allRoundHistoryData },
          allRoundloading: true
        }
      })
      .addCase(getCrashGameRoundDetails.fulfilled, (state, action) => {
        return {
          ...state,
          crashGameRoundDetailsData: action.payload,
          crashGameRoundDetailsLoading: false

        }
      })

      .addCase(getMyBets.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false

        }
      })
      .addCase(getCrashHighRollerTransactions.pending, (state, action) => {
        return {
          ...state,
          betsLoading: true
        }
      })
      .addCase(getCrashHighRollerTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          crashHighRollerTransactions: action?.payload,
          betsLoading: false
        }
      })
      .addCase(getCrashHighRollerTransactions.rejected, (state, action) => {
        return {
          ...state,
          betsLoading: false
        }
      })
  }
})

export {
  setRoundBetResult,
  setPlacedBetsData,
  setResultData,
  setDiceGameBetHistoryData,
  setTopBetsData,
  setCurrentRoundId,
  setPlacedCrashBetId,
  setShowCrashGameHistoryModal,
  setSelectedRoundHashForFairness
}

export default reducer
