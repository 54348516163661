import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { PAGINATION_LIMIT } from '../../utils/constants'

const Pagination = ({ currentOffset, onPageChange, disableNext }) => {
  const { t } = useTranslation('transaction')

  return (
    <nav aria-label='Page navigation example'>
      <ul className='pagination custom-pagination '>
        <li className='page-item'>
          <a
            className={`page-link ${currentOffset >= PAGINATION_LIMIT ? 'active' : ''}`} onClick={() => {
              // Check is Previous page available
              if (currentOffset >= PAGINATION_LIMIT) onPageChange(currentOffset - PAGINATION_LIMIT)
            }}
          >
            {t('pagination.prevBtn')}
          </a>
        </li>
        <li className='page-item'><a className='page-link active'>{(currentOffset / PAGINATION_LIMIT) + 1}</a></li>
        <li className='page-item'>
          <a
            className={`page-link ${!disableNext ? 'active' : ''}`} onClick={() => {
              // Check is Next page available
              if (!disableNext) onPageChange(currentOffset + PAGINATION_LIMIT)
            }}
          >
            {t('pagination.nextBtn')}
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default memo(Pagination)
