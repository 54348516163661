import { METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis'

export const getBlackJackGameBetHistory = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'black-jack-game/get-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const blackJackGamePlaceBet = ({ data, isDemoGame }) => {
  return axiosInstance(METHOD_TYPES.post, 'black-jack-game/place-bet', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackGameHitBet = ({ data }) => {
  return axiosInstance(METHOD_TYPES.post, 'black-jack-game/hit', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackGameStandBet = ({ data }) => {
  return axiosInstance(METHOD_TYPES.post, 'black-jack-game/stand', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackGameDoubleBet = ({ data }) => {
  return axiosInstance(METHOD_TYPES.post, 'black-jack-game/double-bet', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackGameSplitBet = ({ data, isDemoGame }) => {
  return axiosInstance(METHOD_TYPES.post, 'black-jack-game/split-bet', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackUnfinishedBet = () => {
  return axiosInstance(METHOD_TYPES.get, 'black-jack-game/unfinished-bet', {}, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackPlaceInsuranceBet = ({ data }) => {
  return axiosInstance(METHOD_TYPES.post, 'black-jack-game/place-insurance', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackUpdateCurrentHandStatus = ({ data }) => {
  return axiosInstance(METHOD_TYPES.post, 'black-jack-game/update-current-hand-status', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackCheckFairness = ({ data }) => {
  return axiosInstance(METHOD_TYPES.post, 'black-jack-game/check-fairness', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const getMyBlackJackTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/black-jack-game/my-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getBlackJackHighRollerTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/black-jack-game/top-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getBlackJackTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/black-jack-game/all-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}
