import {
  startGame,
  stopGame,
  setValuesToInitial,
  setAnimation,
  setInstantBet
} from './gameLogic.js'
import {
  createUI
} from './gameUI.js'

// startLoadingAssets()

export function gameManager () {
  createUI()
}

export function resetGame () {
  setValuesToInitial()
}

export function start () {
  resetGame()
  startGame()
}

export function stop (x, onBallStoppedAfterSpin) {
  stopGame(x, onBallStoppedAfterSpin)
}
// toggleInstantBet(true)
export function toggleInstantBet (bool) {
  setInstantBet(bool)
}

export function toggleAnimation (bool) {
  setAnimation(bool)
}

export function ballStoppedAtTarget () {
  console.log('Ball Stopped')
}

// const resetDiv = document.createElement('button')
// resetDiv.innerHTML = 'reset'
// resetDiv.onclick = resetGame
// document.body.appendChild(resetDiv);

// const startDiv = document.createElement('button')
// startDiv.innerHTML = 'start'
// startDiv.onclick = start
// document.body.appendChild(startDiv);

// const stopDiv = document.createElement('button')
// stopDiv.innerHTML = 'stop'
// stopDiv.onclick = stop
// document.body.appendChild(stopDiv);

// const AnimationOnDiv = document.createElement('button')
// AnimationOnDiv.innerHTML = 'Animation On'
// AnimationOnDiv.onclick = toggleAnimation(true)
// document.body.appendChild(AnimationOnDiv);

// const AnimationOffDiv = document.createElement('button')
// AnimationOffDiv.innerHTML = 'Animation Off'
// AnimationOffDiv.onclick = toggleAnimation(false)
// document.body.appendChild(AnimationOffDiv);

// const InstantBetOnDiv = document.createElement('button')
// InstantBetOnDiv.innerHTML = 'InstantBet On'
// InstantBetOnDiv.onclick = toggleInstantBet(true)
// document.body.appendChild(InstantBetOnDiv);

// const InstantBetOffDiv = document.createElement('button')
// InstantBetOffDiv.innerHTML = 'InstantBet Off'
// InstantBetOffDiv.onclick = toggleAnimation(false)
// document.body.appendChild(InstantBetOffDiv);
