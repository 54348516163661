import React from 'react'
import Draggable from 'react-draggable'

const DraggablePopup = ({ id, title, onClose, children }) => {
  return (
    <div className='modal fade' id={id} aria-hidden='true'>
      <div className='modal-dialog modal-dialog-scrollable modal-sm'>
        <Draggable handle='.modal-header'>
          <div className='modal-content'>
            <div className='modal-header' style={{ cursor: 'all-scroll' }}>
              <h5 className='modal-title' id='exampleModalLabel'>{title}</h5>
            </div>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' style={{ position: 'absolute', top: 10, right: 10 }} onClick={onClose}>
              <i className='fa fa-times fa-lg' aria-hidden='true' style={{ color: 'white' }} />
            </button>
            <div className='modal-body'>
              {children}
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  )
}

export default DraggablePopup
