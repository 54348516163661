import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
// import { useLocation, useHistory } from 'react-router-dom'
import ErrorMessage from '../../../components/ErrorMessage'
import { depositAmountRequest } from '../../../redux-store/redux/thunk/Users/User'
import Loader from '../../../components/Loader'
import { LOADER } from '../../../utils/constants'
import { setDepositData } from '../../../redux-store/redux-slices/walletSlice'

export const Deposit = ({ setShowWalletPopup }) => {
  const dispatch = useDispatch()
  const { depositData, loading } = useSelector(state => state.wallet)
  const { addToast } = useToasts()
  const schema = yup.object().shape({
    amount: yup.number()
      .typeError('Amount is required')
      .required('Amount is required')
      .moreThan(100, 'Deposit amount must be greater than 100 Rs')
  })

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const handleDepositSubmit = (values) => {
    dispatch(depositAmountRequest({ values, addToast }))
  }

  useEffect(() => {
    if (depositData?.data?.pay_url) {
      window?.open(depositData?.data?.pay_url, '_blank')
      setShowWalletPopup(false)
      dispatch(setDepositData())
    }
  }, [depositData?.data?.pay_url])

  return (
    <div className='tab-pane fade show active  text-white' id='pills-deposit' role='tabpanel' aria-labelledby='pills-home-tab'>
      <form onSubmit={handleSubmit(handleDepositSubmit)}>
        <div className='row g-2'>
          <div className='form-group required col-md-12 d-flex flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='amount'>Amount</label>
            <input
              type='number'
              className='form-control'
              id='amount'
              name='amount'
              autoComplete='off'
              maxLength={20}
              {...register('amount')}
            />
            {errors && errors.amount && (
              <ErrorMessage
                className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                message={errors.amount.message}
              />
            )}
          </div>
          {/* {depositData?.data?.pay_url && <iframe src={depositData?.data?.pay_url + '&output=embed'} title='Deposit' />} */}
          <div>
            <button
              type='submit'
              className='btn btn-primary mt-2 mb-3'
              style={{ width: '100%', lineHeight: '40px' }}
            >
              {loading
                ? (
                  <Loader component={LOADER.BUTTON} />
                  )
                : (
                    'Submit'
                  )}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
