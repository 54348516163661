import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllCurrencyService, getAllSiteinfoService, getBannerService, getEncourageWordsSettingService, getGameImagesService, getGameSettingService, getGameThemeSettingService, getListWonderGirlsService, getSponserService, setUpdateAffilateCountService } from '../../../network/services/gamesetting'
import { setAllcurrency } from '../../redux-slices/gameSetting'
import { getGameIframeUrlService } from '../../../network/services/sportsBook.service'

export const getAllCurrency = createAsyncThunk(
  'user/getAllCurrency',
  async (_, thunkApi) => {
    try {
      const res = await getAllCurrencyService()
      thunkApi.dispatch(setAllcurrency(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

// export const updateCurrencySetting = createAsyncThunk(
//   'user/updateCurrencySetting',
//   async ({ params }, thunkApi) => {
//     try {
//       const res = await updateCurrencySettingService(params)
//       thunkApi.dispatch(fetchUserInformation())
//       return res
//     } catch (error) {
//       return thunkApi.rejectWithValue(error[0].description)
//     }
//   }
// )
export const getGameSetting = createAsyncThunk(
  'system/get-game-settings',
  async (_, thunkApi) => {
    try {
      const res = await getGameSettingService()
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getAllSiteinfo = createAsyncThunk(
  'system/get-all-cms',
  async (_, thunkApi) => {
    try {
      const res = await getAllSiteinfoService()
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getGameThemeSetting = createAsyncThunk(
  'system/get-gameTheme-settings',
  async (_, thunkApi) => {
    try {
      const res = await getGameThemeSettingService()
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getEncourageWordsSetting = createAsyncThunk(
  'system/list-uplifting-words',
  async (_, thunkApi) => {
    try {
      const res = await getEncourageWordsSettingService()
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const setUpdateAffilateCount = createAsyncThunk(
  'system/increase-affiliateCount',
  async (params, thunkApi) => {
    try {
      const res = await setUpdateAffilateCountService(params)
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getBanner = createAsyncThunk(
  'system/get-banner',
  async (_, thunkApi) => {
    try {
      const res = await getBannerService()
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getGameImages = createAsyncThunk(
  'system/get-game-images-list',
  async (_, thunkApi) => {
    try {
      const res = await getGameImagesService()
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getWonderGirlsImages = createAsyncThunk(
  'system/get-wonder-girls-images',
  async (_, thunkApi) => {
    try {
      const res = await getListWonderGirlsService()
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getSponser = createAsyncThunk(
  'system/get-sponser-banner',
  async (_, thunkApi) => {
    try {
      const res = await getSponserService()
      //  thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getGameIframe = createAsyncThunk(
  'game/get-iframe-url',
  async (data, thunkApi) => {
    try {
      const res = await getGameIframeUrlService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
