import React, { memo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import {
  faAngleDown,
  // faUpload,
  faCopy
} from '@fortawesome/free-solid-svg-icons'
import { generateWalletAddress } from '../../../redux-store/redux/thunk/Users/User'
import { copyToClipboard } from '../../../utils/helper'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import Loader from '../../Loader'
import { LOADER } from '../../../utils/constants'

const DepositSection = ({
  selectedCurrency,
  cryptoCurrencies,
  primaryCurrency,
  handleCurrencyChange,
  selectedWallet,
  handleConversion,
  conversionState
}) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { t } = useTranslation('transaction')
  const [addressLoading, setAddressLoading] = useState(false)

  const handleCopyCode = (value) => {
    const isCopySuccessfull = copyToClipboard(value)
    if (!isCopySuccessfull) {
      return addToast(t('deposit.copyNotSupported'), { appearance: 'error' })
    }

    addToast(t('deposit.copied'), { appearance: 'success' })
    return null
  }

  const generateAddress = async (wallet) => {
    if (selectedWallet?.amount < conversionState?.fiatAmount) {
      return addToast('Insufficient Balance!!!', { appearance: 'error' })
    }
    if (!wallet?.walletAddress) {
      setAddressLoading(true)
      const params = {
        currency_id: wallet.currency.id,
        code: wallet.currency.code,
        network: wallet.currency.name.toLowerCase()
      }
      await dispatch(generateWalletAddress({ params }))
      setAddressLoading(false)
    }
  }

  return (
    <div
      className='tab-pane fade show active'
      id='deposit'
      role='tabpanel'
      aria-labelledby='deposit-tab'
    >
      {/* FIRST CONTENT WRAP START */}
      {/* <div className='content-wrap content-wrap-border'>
      <div className='content-box content-border'>
        <h5 className='title-text'>Verification</h5>
        <p className='pera-text'>Lorem Ipsum is simply dummy text.</p>
        <p className='pera-text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <hr className='custom-hr' />
        <div className='note-text-box'>
          <h6 className='heading-text'>Lorem Ipsum is simply dummy text.</h6>
          <p className='pera-text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <label className='form-label'>Proof of Identity (Front) <span className='text-danger ms-1'>*</span></label>
            <div className='input-group mb-3'>
              <input type='file' className='form-control' id='identityProof-Front' />
              <label className='input-group-text' htmlFor='identityProof-Front'>
                <span className='text text-truncate'>Choose File</span>
                <span className='icon'>
                  <FontAwesomeIcon icon={faUpload} />
                </span>
              </label>
            </div>
          </div>
          <div className='col-sm-12'>
            <label className='form-label'>Proof of Identity (Back) <span className='text-danger ms-1'>*</span></label>
            <div className='input-group mb-3'>
              <input type='file' className='form-control' id='identityProof-Back' />
              <label className='input-group-text' htmlFor='identityProof-Back'>
                <span className='text text-truncate'>Choose File</span>
                <span className='icon'>
                  <FontAwesomeIcon icon={faUpload} />
                </span>
              </label>
            </div>
          </div>
          <div className='col-sm-12'>
            <p className='indicate-text'>Following file types are accepted: .png, .jpg, .pdf</p>
          </div>
        </div>
      </div>
      <div className='content-btn-box'>
        <div className='text-box'>
          <span className='text'>Lorem Ipsum is simply dummy text</span>
        </div>
        <div className='btn-box'>
          <button type='button' className='btn secondary-btn'>Submit</button>
        </div>
      </div>
    </div> */}
      {/* FIRST CONTENT WRAP END */}

      {/* SECOND CONTENT WRAP START */}
      <div className='content-wrap'>
        <div className='content-box'>
          <div className='row'>
            <div className='col-12'>
              <div className='dropdown mb-3'>
                <label className='form-label'>Currency</label>
                <button
                  className='btn primary-btn w-100'
                  type='button'
                  id='currencyDropdown'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <span className='icon'>
                    <img
                      src={`/assets/images/currency/currency-${
                        selectedCurrency?.currency?.code.toLowerCase() ||
                        'default'
                      }.png`}
                      className='img-fluid'
                      alt='Icon'
                    />
                  </span>
                  <span className='text'>
                    {selectedCurrency?.currency?.code}
                  </span>
                  <FontAwesomeIcon icon={faAngleDown} className='ms-auto' />
                </button>
                <ul
                  className='dropdown-menu'
                  aria-labelledby='currencyDropdown'
                >
                  {cryptoCurrencies &&
                    Object.keys(cryptoCurrencies)?.map((crypto) => (
                      <li key={crypto}>
                        <a
                          href='#'
                          className='dropdown-item'
                          onClick={() =>
                            handleCurrencyChange(cryptoCurrencies[crypto])}
                        >
                          <span className='icon'>
                            <img
                              src={`/assets/images/currency/currency-${
                                crypto.toLowerCase() || 'default'
                              }.png`}
                              className='img-fluid'
                              alt='Icon'
                            />
                          </span>
                          <span className='text'>{crypto}</span>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className='col-12'>
              <div className='d-flex justify-content-between gap-3'>
                <div className='form-group mb-3'>
                  <label className='form-label mx-1'>
                    {selectedCurrency?.currency?.code}
                  </label>
                  <input
                    type='number'
                    style={{
                      '--input-coin-img': `url('/assets/images/currency/currency-${
                        selectedCurrency?.currency?.code.toLowerCase() ||
                        'default'
                      }.png')`
                    }}
                    className='form-control input-coin'
                    value={conversionState?.cryptoAmount || 0}
                    onChange={(e) => handleConversion(e, false)}
                  />
                </div>
                <div className='align-self-end'>
                  <h3 className='mb-4 text-white'>=</h3>
                </div>
                <div className='form-group mb-3'>
                  <label className='form-label mx-1'>{primaryCurrency}</label>
                  <input
                    type='number'
                    style={{
                      '--input-coin-img': `url('/assets/images/currency/currency-${
                        selectedWallet?.currency?.code.toLowerCase() ||
                        'default'
                      }.png')`
                    }}
                    className='form-control input-coin'
                    value={conversionState?.fiatAmount || 0}
                    onChange={(e) => handleConversion(e, true)}
                  />
                </div>
              </div>
              <div className='col-sm-12'>
                <p className='pera-text text-center'>
                  The value of {selectedCurrency?.currency?.code} may change
                  between now and the time we receive your payment
                </p>
              </div>
            </div>
            {/* <div className='col-sm-4 col-6'>
            <div className='dropdown mb-3'>
              <label className='form-label'>Network</label>
              <button className='btn primary-btn w-100' type='button' id='currencyDropdown' data-bs-toggle='dropdown' aria-expanded='false'>
                <span className='text'>ETH</span>
                <FontAwesomeIcon icon={faAngleDown} className='ms-auto' />
              </button>
              <ul className='dropdown-menu' aria-labelledby='currencyDropdown'>
                <li>
                  <a href='#' className='dropdown-item'>
                    <span className='text'>Transactions</span>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
            {selectedCurrency?.walletAddress
              ? (
                <>
                  <div className='col-12 mx-auto'>
                    <label className='form-label'>
                      Your {selectedCurrency?.currency?.code || ''} Deposit
                      Address
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control small'
                        value={selectedCurrency?.walletAddress || ''}
                        readOnly
                      />
                      <span className='addon addon-right cursor-pointer'>
                        <button
                          type='button'
                          className='input-btn'
                          onClick={() =>
                            handleCopyCode(selectedCurrency?.walletAddress || '')}
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className='col-sm-5 col-6 mx-auto mb-3'>
                    <div className='qr-code-img-box mb-3'>
                      {selectedCurrency?.walletAddress
                        ? (
                          <QRCode
                            value={selectedCurrency?.walletAddress}
                            className='img-fluid d-block mx-auto'
                            size={150}
                          />
                          )
                        : (
                            'Address Unavailable'
                          )}
                    </div>
                  </div>
                  <div className='col-sm-12'>
                    <p className='pera-text text-center'>
                      Only send ETH to this address, 2 confirmations required.
                    </p>
                  </div>
                </>
                )
              : (
                <div className='col-6 mx-auto'>
                  <button
                    type='button'
                    className='btn secondary-btn w-100 my-4'
                    onClick={() => generateAddress(selectedCurrency)}
                  >
                    {addressLoading
                      ? (
                        <Loader component={LOADER.BUTTON} />
                        )
                      : (
                          'Generate Address'
                        )}
                  </button>
                </div>
                )}
          </div>
        </div>
      </div>
      {/* SECOND CONTENT WRAP END */}
    </div>
  )
}

export default memo(DepositSection)
