import React from 'react'
import './partnership.scss'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'
import { PartnerProgramsTabs } from '../../../utils/constants'

const Partnership = () => {
  return (
    <div className='main-container'>
      <section className='container mt-3'>
        <div className='partnership-section'>
          <h2 className='text-center'>
            PARTNERSHIPS
          </h2>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='d-flex flex-column align-items-center background-shades'>
                <h3 className='text-uppercase'>
                  <span className='before-line' />
                  <p className='m-0 text-center'>become an affiliate</p>
                  <span className='after-line' />
                </h3>
                <p className='text-uppercase title-text text-center'>
                  Get Paid to drive traffic to our site!
                </p>
                <NavLink to={`/user/become-partner/partner/${PartnerProgramsTabs[0].path}`} className='gap-2 feedback-btn border-0 btn secondary-btn d-flex justify-content-center'>
                  Submit Form
                </NavLink>
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='d-flex flex-column align-items-center'>
                <h3 className='text-uppercase'>
                  <span className='before-line' />
                  <p className='m-0 text-center'>become an early investor</p>
                  <span className='after-line' />
                </h3>
                <p className='text-uppercase text-center title-text'>
                  high roller? get in on the action  early and grow with us!
                </p>
                <NavLink to={`/user/become-partner/partner/${PartnerProgramsTabs[1].path}`} className='gap-2 feedback-btn border-0 btn secondary-btn d-flex justify-content-center'>
                  Submit Form
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Partnership
