import './settings.scss'
import React, { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faCopy
} from '@fortawesome/free-solid-svg-icons'

import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToasts } from 'react-toast-notifications'
import ErrorMessage from '../../components/ErrorMessage'
import { changeUserPassword } from '../../redux-store/redux/thunk/Users/User'

const SettingsSection = () => {
  const { t } = useTranslation('home')
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [showPassword, setShowPassword] = useState(false)

  const changePasswordSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(`${t('signin.errors.oldPassword.required')}`)
      .min(8, `${t('signin.errors.password.minLength')}`)
      .max(16, `${t('signin.errors.password.maxLength')}`),
    newPassword: yup
      .string()
      .required(`${t('signin.errors.newPassword.required')}`)
      .min(8, `${t('signin.errors.password.minLength')}`)
      .max(16, `${t('signin.errors.password.maxLength')}`),
    confirmNewPassword: yup
      .string()
      .required(`${t('signin.errors.confirmNewPassword.required')}`)
      .oneOf([yup.ref('newPassword'), null], `${t('signin.errors.confirmNewPassword.shouldMatch')}`)
      .min(8, `${t('signin.errors.password.minLength')}`)
      .max(16, `${t('signin.errors.password.maxLength')}`)
  })

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [showPassword, setShowPassword])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
    mode: 'all'
  })

  const handleSubmitPassword = (data) => {
    const payload = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword
    }
    dispatch(changeUserPassword({ payload, addToast }))
    reset()
  }

  return (
    <main className='main-section'>
      <section className='transaction-section profile-inner-section'>
        <div className='container'>
          <h1 className='section-heading'>Settings</h1>
          <div className='profile-inner-wrap'>
            <div className='tabs-nav-wrap'>
              <ul className='nav' id='pills-tab' role='tablist'>
                <li className='nav-item' role='presentation'>
                  <button className='nav-link active' id='pills-general-tab' data-bs-toggle='pill' data-bs-target='#pills-general' type='button' role='tab' aria-controls='pills-general' aria-selected='true'>General</button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button className='nav-link' id='pills-security-tab' data-bs-toggle='pill' data-bs-target='#pills-security' type='button' role='tab' aria-controls='pills-security' aria-selected='false'>Security</button>
                </li>
              </ul>
            </div>
            <div className='tabs-content-wrap'>
              <div className='tabs-content-box'>
                <div className='tab-content' id='pills-tabContent'>
                  <div className='tab-pane fade show active' id='pills-general' role='tabpanel' aria-labelledby='pills-general-tab'>
                    <div className='content-box'>
                      <form>
                        <div className='card'>
                          <div className='card-header'>
                            <h2 className='title-text'>Email</h2>
                          </div>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='col-sm-6'>
                                <label className='form-label'>Email <span className='text-danger ms-1'>*</span></label>
                                <div className='input-group mb-3'>
                                  <input type='email' className='form-control' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='card-footer'>
                            <div className='btn-box'>
                              <button type='button' className='btn text-btn'>Resend Email</button>
                              <button type='button' className='btn secondary-btn'>Save</button>
                            </div>
                          </div>
                        </div>
                      </form>

                      <form>
                        <div className='card'>
                          <div className='card-header'>
                            <h2 className='title-text'>Phone Number</h2>
                            <h3 className='subtitle-text'>We only service areas that are listed in the available country code list.</h3>
                          </div>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='col-sm-6'>
                                <label className='form-label'>Country Code <span className='text-danger ms-1'>*</span></label>
                                <div className='input-group mb-3'>
                                  <select className='form-select'>
                                    <option selected>Open this select menu</option>
                                    <option value='1'>One</option>
                                    <option value='2'>Two</option>
                                    <option value='3'>Three</option>
                                  </select>
                                </div>
                              </div>
                              <div className='col-sm-6'>
                                <label className='form-label'>Phone Number <span className='text-danger ms-1'>*</span></label>
                                <div className='input-group mb-3'>
                                  <input type='text' className='form-control' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='card-footer'>
                            <div className='btn-box'>
                              <button type='button' className='btn secondary-btn'>Submit</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className='tab-pane fade' id='pills-security' role='tabpanel' aria-labelledby='pills-security-tab'>
                    <div className='content-box'>
                      <form onSubmit={handleSubmit(handleSubmitPassword)}>
                        <div className='card'>
                          <div className='card-header'>
                            <h2 className='title-text'>Password</h2>
                          </div>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='col-sm-6'>
                                <label className='form-label'>Old Password <span className='text-danger ms-1'>*</span></label>
                                <div className='input-group mb-3'>
                                  <input
                                    type={showPassword ? 'text' : 'password'}
                                    className='form-control'
                                    name='oldPassword'
                                    maxLength={50}
                                    autoComplete='off'
                                    {...register('oldPassword')}
                                  />
                                  <span className='addon addon-right cursor-pointer'>
                                    <button type='button' className='input-btn' onClick={handleShowPassword}>
                                      <FontAwesomeIcon icon={faEye} />
                                      {/* <FontAwesomeIcon icon={faEyeSlash} /> */}
                                    </button>
                                  </span>
                                </div>
                                {errors && errors.oldPassword && (
                                  <ErrorMessage
                                    className='text-danger error-msg login-input small'
                                    message={errors.oldPassword.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-sm-6'>
                                <label className='form-label'>New Password <span className='text-danger ms-1'>*</span></label>
                                <div className='input-group mb-3'>
                                  <input
                                    type={showPassword ? 'text' : 'password'}
                                    className='form-control'
                                    name='newPassword'
                                    maxLength={50}
                                    autoComplete='off'
                                    {...register('newPassword')}
                                  />
                                  <span className='addon addon-right cursor-pointer'>
                                    <button type='button' className='input-btn' onClick={handleShowPassword}>
                                      <FontAwesomeIcon icon={faEye} />
                                      {/* <FontAwesomeIcon icon={faEyeSlash} /> */}
                                    </button>
                                  </span>
                                </div>
                                {errors && errors.newPassword && (
                                  <ErrorMessage
                                    className='text-danger error-msg login-input small'
                                    message={errors.newPassword.message}
                                  />
                                )}
                              </div>
                              <div className='col-sm-6'>
                                <label className='form-label'>Confirm New Password <span className='text-danger ms-1'>*</span></label>
                                <div className='input-group mb-3'>
                                  <input
                                    type={showPassword ? 'text' : 'password'}
                                    className='form-control'
                                    name='confirmNewPassword'
                                    maxLength={50}
                                    autoComplete='off'
                                    {...register('confirmNewPassword')}
                                  />
                                  <span className='addon addon-right cursor-pointer'>
                                    <button type='button' className='input-btn' onClick={handleShowPassword}>
                                      <FontAwesomeIcon icon={faEye} />
                                      {/* <FontAwesomeIcon icon={faEyeSlash} /> */}
                                    </button>
                                  </span>
                                </div>
                                {errors && errors.confirmNewPassword && (
                                  <ErrorMessage
                                    className='text-danger error-msg login-input small'
                                    message={errors.confirmNewPassword.message}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='card-footer'>
                            <div className='btn-box'>
                              <button
                                type='submit'
                                className='btn secondary-btn'
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>

                      <form>
                        <div className='card'>
                          <div className='card-header'>
                            <h2 className='title-text'>Two Factor</h2>
                            <h3 className='subtitle-text'>To keep your account extra secure leave a two factor authentication enabled.</h3>
                          </div>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='col-sm-6'>
                                <label className='form-label'>Copy this code to your Authenticator App</label>
                                <div className='input-group mb-3'>
                                  <input type='text' className='form-control small' value='0xe18dbfb6a45ddacf68d5d1d4ed97c2253ab623d2' />
                                  <span className='addon addon-right cursor-pointer'>
                                    <button type='button' className='input-btn'>
                                      <FontAwesomeIcon icon={faCopy} />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <div className='col-sm-12'>
                                <label className='form-label'>Don't let anyone see this!</label>
                                <div className='qr-code-img-box mb-3'>
                                  <img src='/assets/images/stock-images/qr-code.png' className='img-fluid d-block mx-auto' alt='QR Code' />
                                </div>
                              </div>
                              <div className='col-sm-6'>
                                <label className='form-label'>Password <span className='text-danger ms-1'>*</span></label>
                                <div className='input-group mb-3'>
                                  <input type='password' className='form-control' />
                                  <span className='addon addon-right cursor-pointer'>
                                    <button type='button' className='input-btn'>
                                      <FontAwesomeIcon icon={faEye} />
                                      {/* <FontAwesomeIcon icon={faEyeSlash} /> */}
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <div className='col-sm-6'>
                                <label className='form-label'>Two Factor Code <span className='text-danger ms-1'>*</span></label>
                                <div className='input-group mb-3'>
                                  <input type='text' className='form-control' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='card-footer'>
                            <div className='btn-box'>
                              <button
                                type='button'
                                className='btn secondary-btn'
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default SettingsSection
