import { divide } from 'number-precision'
import { cartesianProductOfArrays } from '../../../../../utils/common-functions'

// There are 3 horizontals and 12 verticals in this matrix
export const ROULETTE = [[3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36], [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35], [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34]]

export const ROULETTE_BET_TYPES = {
  1: 'straight',
  2: 'split-horizontal',
  3: 'street',
  4: 'corner',
  5: 'line',
  6: 'column',
  7: 'dozen',
  8: 'odd-even',
  9: 'color',
  10: 'low-high',
  11: 'split-vertical'
}

export const DOZEN_MAP = {
  1: '1-12',
  2: '13-24',
  3: '25-36'
}

export const LOW_HIGH_MAP = {
  1: '1-18',
  2: '19-36'
}

export const ROULETTE_PAYOUTS = {
  1: 36,
  2: 18,
  3: 12,
  4: 9,
  5: 6,
  6: 3,
  7: 3,
  8: 2,
  9: 2,
  10: 2,
  11: 18
}

export const ROULETTE_COLORS_INDICES = {
  1: [[0, 0], [0, 2], [0, 3], [0, 5], [0, 6], [0, 8], [0, 9], [0, 11], [2, 0], [2, 2], [2, 5], [2, 6], [2, 8], [2, 11], [1, 1], [1, 4], [1, 7], [1, 10]],
  2: [[0, 1], [0, 4], [0, 7], [0, 10], [2, 1], [2, 4], [2, 7], [2, 10], [1, 0], [1, 2], [1, 3], [1, 5], [1, 6], [1, 8], [1, 9], [1, 11], [2, 3], [2, 9]]
}

export const FORMAL_NUMBERS = {
  1: '1st',
  2: '2nd',
  3: '3rd'
}

export const ROULETTE_BETS = {
  1: straightNo => {
    if (straightNo < 0 || straightNo > 36) {
      return null
    }
    return [straightNo]
  },
  2: splitNo => {
    if (splitNo < 1 || splitNo > 33) {
      return null
    }
    const horizontal = Math.ceil(splitNo / 11) - 1
    const vertical = (splitNo - 1) % 11
    return [ROULETTE[horizontal][vertical], ROULETTE[horizontal][vertical + 1]]
  },
  3: streetNo => {
    if (streetNo < 1 || streetNo > 12) {
      return null
    }
    const horizontals = [0, 1, 2]
    const verticals = [streetNo - 1]
    const numbers = cartesianProductOfArrays(horizontals, verticals).map(item => ROULETTE[item[0]][item[1]])
    return numbers
  },
  4: cornerNo => {
    if (cornerNo < 1 || cornerNo > 22) {
      return null
    }
    const horizontals = [Math.ceil(cornerNo / 11) - 1, Math.ceil(cornerNo / 11) - 1 + 1]
    const verticals = [(cornerNo - 1) % 11, (cornerNo - 1) % 11 + 1]
    const numbers = cartesianProductOfArrays(horizontals, verticals).map(item => ROULETTE[item[0]][item[1]])
    return numbers
  },
  5: lineNo => {
    if (lineNo < 1 || lineNo > 11) {
      return null
    }
    const horizontals = [0, 1, 2]
    const verticals = [lineNo - 1, lineNo]
    const cartesian = cartesianProductOfArrays(horizontals, verticals)
    const numbers = cartesian.map(item => ROULETTE[item[0]][item[1]])
    return numbers
  },
  6: columnNo => {
    if (columnNo < 1 || columnNo > 3) {
      return null
    }
    const horizontals = [(columnNo - 1)]
    const verticals = Array.from(Array(12).keys())
    const cartesian = cartesianProductOfArrays(horizontals, verticals)
    const numbers = cartesian.map(item => ROULETTE[item[0]][item[1]])
    return numbers
  },
  7: dozenNo => {
    // 1 for first 12, 2 for second 12 and 3 for third 12
    if (dozenNo < 1 || dozenNo > 3) {
      return null
    }
    const horizontals = [0, 1, 2]
    const verticals = [0, 1, 2, 3].map(item => (item + (dozenNo - 1) * 4))
    const cartesian = cartesianProductOfArrays(horizontals, verticals)
    const numbers = cartesian.map(item => ROULETTE[item[0]][item[1]])
    return numbers
  },
  8: oddOrEven => {
    // 1 for odd, 2 for even
    if (oddOrEven < 1 || oddOrEven > 2) {
      return null
    }
    const numbers = oddOrEven === 1 ? Array.from(Array(36).keys(), x => x + 1).filter(x => x % 2 === 1) : Array.from(Array(36).keys(), x => x + 1).filter(x => x % 2 === 0)
    return numbers
  },
  9: color => {
    // 1 for red, 2 for black
    if (color < 1 || color > 2) {
      return null
    }
    const numbers = ROULETTE_COLORS_INDICES[color].map(point => ROULETTE[point[0]][point[1]])
    return numbers
  },
  10: lowOrHigh => {
    // 1 for first 18, 2 for second 18
    if (lowOrHigh < 1 || lowOrHigh > 2) {
      return null
    }
    const horizontals = [0, 1, 2]
    const verticals = [0, 1, 2, 3, 4, 5].map(item => (item + (lowOrHigh - 1) * 6))
    const cartesian = cartesianProductOfArrays(horizontals, verticals)
    const numbers = cartesian.map(item => ROULETTE[item[0]][item[1]])
    return numbers
  },
  11: splitNo => {
    if (splitNo < 1 || splitNo > 24) {
      return null
    }
    const horizontal = Math.ceil(splitNo / 12) - 1
    const vertical = (splitNo - 1) % 12
    return [ROULETTE[horizontal][vertical], ROULETTE[horizontal + 1][vertical]]
  }
}

// Remember all the straight bets should be at the last
export const defaultBetDetails = [
  '6_1', '6_2', '6_3',
  '7_1', '7_2', '7_3', '8_1', '8_2', '9_1', '9_2', '10_1', '10_2',
  '1_0', '1_1', '1_2', '1_3', '1_4',
  '1_5', '1_6', '1_7', '1_8', '1_9',
  '1_10', '1_11', '1_12', '1_13', '1_14',
  '1_15', '1_16', '1_17', '1_18', '1_19',
  '1_20', '1_21', '1_22', '1_23', '1_24',
  '1_25', '1_26', '1_27', '1_28', '1_29',
  '1_30', '1_31', '1_32', '1_33', '1_34',
  '1_35', '1_36'
]

// export const initialAutoBetConditions = {
//   stopOnProfit: '0.000000',
//   stopOnLoss: '0.0000000',
//   onWin: 0,
//   onLoss: 0
// }

export const initialStateBetDetailsMap = () => {
  const obj = {}
  defaultBetDetails.forEach(item => {
    obj[item] = { chips: 0, amount: 0 }
  })
  return obj
}

export const rouletteNumberColor = (num) => {
  let result
  if (num === 0) {
    return 'color-zero'
  }
  Object.keys(ROULETTE_COLORS_INDICES).forEach(color => {
    if (ROULETTE_COLORS_INDICES[color].some(point => ROULETTE[point[0]][point[1]] === num)) {
      result = +color
    }
  })
  return result === 1 ? 'color-red' : 'color-black'
}

export const showChipsInFormat = chips => {
  if (chips >= 1000) {
    return `${parseInt(divide(chips, 1000))}K${chips % 1000 === 0 ? '' : '+'}`
  }
  if (chips >= 1000000) {
    return `${parseInt(divide(chips, 1000))}M${chips % 1000 === 0 ? '' : '+'}`
  }

  return chips
}

export const getWinningBetTypes = winningNumber => {
  const results = []
  defaultBetDetails.forEach(betDetail => {
    const betType = +betDetail.split('_')[0]
    const typeDetail = +betDetail.split('_')[1]

    const isWin = ROULETTE_BETS[betType](typeDetail).includes(winningNumber)
    let content
    if (isWin) {
      switch (betType) {
        case 6:
          content = `${FORMAL_NUMBERS[typeDetail]} Column`
          break
        case 7:
          content = `${12 * (typeDetail - 1) + 1} to ${12 * (typeDetail)}`
          break
        case 8:
          content = typeDetail === 1 ? 'Odd' : 'Even'
          break
        case 9:
          content = typeDetail === 1 ? 'Red' : 'Black'
          break
        case 10:
          content = typeDetail === 1 ? 'Low' : 'High'
          break
      }
    }
    if (content) {
      results.push(content)
    }
  })
  return results
}
