import React from 'react'
import { Redirect } from 'react-router-dom'

const PrivateRoute = ({ Component, isAuthenticated, child }) => {
  return isAuthenticated
    ? (
      <Component child={child} />
      )
    : <Redirect to={{ pathname: '/' }} />
}

export default PrivateRoute
