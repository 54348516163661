import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSiteinfo } from '../../redux-store/redux/thunk/gameSetting'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './footer.scss'

const Footer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('home')
  const { siteInfo } = useSelector(state => state.gameSetting)

  useEffect(() => {
    if (!siteInfo) { dispatch(getAllSiteinfo()) }
  }, [])

  return (
    <footer className='footer-section'>
      <div className='container'>
        <div className='content-wrap'>
          <a href='#' className='footer-logo-box'>
            <img src='/assets/images/logo/footer-logo.png' className='img-fluid d-block mx-auto' alt='Beand-logo' />
          </a>
          <p className='pera-text'>{t('footer.copywrite')}</p>
          <div className='site-info-container mb-3'>
            {siteInfo?.map(info => (
              <span key={info?.id} className='mx-2'>
                <Link to={`site-info/${info?.slug}`} className='site-info-title'>{info?.title}</Link>
              </span>
            ))}
          </div>
          <p className='pera-text'>AfterHours Casino offers an electrifying gaming experience with a diverse selection of classic and modern casino games. Dive into the thrill of real-time action, from traditional card games to cutting-edge slots. Our user-friendly app ensures seamless navigation, while exclusive bonuses and promotions elevate the excitement. Join us for an immersive journey into the world of AfterHours Casino – where the fun never stops!</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
