import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { capitalize } from 'lodash'
import { getAllFavouriteGames } from '../../redux-store/redux/thunk/game'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import ImageLazyLoad from '../../components/ImageLazyLoad'
import Loader from '../../components/Loader'
import { LOADER, ROUTE_PATHS } from '../../utils/constants'
import { setSelectedCasinoGame } from '../../redux-store/redux-slices/Games/casinoGame'

const limit = 20
const customGamesPage = {
  crash: {
    path: ROUTE_PATHS.CRASH_GAME,
    imgUrl: '/assets/images/stock-images/crash-img.png'
  },
  blackjack: {
    path: ROUTE_PATHS.BLACKJACKGAME,
    imgUrl: '/assets/images/stock-images/blackjack-img.png'

  },
  roulette: {
    path: ROUTE_PATHS.ROULETTE,
    imgUrl: '/assets/images/stock-images/roulette-img.png'
  },
  dice: {
    path: ROUTE_PATHS.DICE_GAME,
    imgUrl: '/assets/images/stock-images/dice-img.png'
  }
}
const FavouritesGamesSection = () => {
  const dispatch = useDispatch()
  const isLoggedIn = getAuthToken()
  const history = useHistory()
  const [offset, setOffset] = useState(20)
  const [favGamesToShow, setFavGameToShow] = useState(null)
  const { favouriteGames, loading: favLoading } = useSelector(state => state.games)

  const goToPlayGamePage = useCallback(async (gameData) => {
    const { gameCode, id } = gameData
    if (isLoggedIn) {
      dispatch(setSelectedCasinoGame(gameData))
      history.push(`/casino/play-game/${id}/${gameCode}`)
    }
  }, [isLoggedIn])

  useEffect(() => {
    // Add the class to the body when the component mounts
    document.body.classList.add('home-wrap-styles')

    // Remove the class from the body when the component unmounts
    return () => {
      document.body.classList.remove('home-wrap-styles')
    }
  }, [])

  useEffect(() => {
    if (favouriteGames) {
      setFavGameToShow([...favouriteGames?.customGames?.rows, ...favouriteGames?.casinoGames?.rows])
    }
  }, [favouriteGames])

  const fetchMore = () => {
    dispatch(getAllFavouriteGames({ offset, limit }))
    setOffset(prev => prev + limit)
  }

  const onCustomGameClick = (game) => {
    history.push(customGamesPage[game?.name].path)
  }

  return (
    <section className='main-section'>
      <section className='home-filter-section'>
        <div className='container'>
          <h2 className='text-white mb-2'>Favourites Games</h2>
          <div className='casino-container row'>
            {favGamesToShow?.length
              ? favGamesToShow?.map(game => {
                  if (game?.Game) {
                    return (
                      <div className='casino-game col-6 col-md-4 col-lg-3 mb-3' type='button' key={game?.Game.id} onClick={() => onCustomGameClick(game?.Game)} data-bs-toggle={isLoggedIn ? '' : 'modal'} data-bs-target={isLoggedIn ? '' : '#loginModal'}>
                        <ImageLazyLoad style={{ objectFit: 'contain' }} src={customGamesPage[game?.Game?.name]?.imgUrl} placeholderImg='/assets/images/casino/AH_CASINO_FALLBACK.jpeg' />
                        <div className='live-player'>
                          <div className='text-white'>{capitalize(game?.Game.name)} Game</div>
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div className='casino-game col-6 col-md-4 col-lg-3 mb-3' type='button' key={game?.CasinoGame?.id} onClick={() => goToPlayGamePage(game?.CasinoGame)} data-bs-toggle={isLoggedIn ? '' : 'modal'} data-bs-target={isLoggedIn ? '' : '#loginModal'}>
                      <ImageLazyLoad src={game?.CasinoGame?.image} placeholderImg='/assets/images/casino/AH_CASINO_FALLBACK.jpeg' />
                      <div className='live-player'>
                        <div className='text-white'>{game?.CasinoGame?.gameName}</div>
                      </div>
                    </div>
                  )
                })
              : (
                <div className='d-flex justify-content-center mt-5 text-white h4'>No games found.</div>
                )}
          </div>
          {favGamesToShow?.length && (
            <button
              className='btn btn-outline-primary d-flex mx-auto my-2 pt-2 px-4'
              disabled={favouriteGames?.casinoGames?.rows?.length >= favouriteGames?.casinoGames?.count}
              onClick={() => {
                if (favouriteGames?.casinoGames?.rows?.length < favouriteGames?.casinoGames?.count) {
                  fetchMore()
                }
              }}
            >
              {favLoading
                ? (
                  <div style={{ width: '60px' }}>
                    <Loader component={LOADER.TABLE_CELL} />
                  </div>
                  )
                : 'Load More'}
            </button>
          )}
        </div>
      </section>
    </section>
  )
}

export default FavouritesGamesSection
