/* ==========================================================================
  LANGUAGES
  ========================================================================== */

const LANGUAGES = [
  { label: 'English', languageCode: 'en' },
  { label: 'German', languageCode: 'de' },
  { label: 'Spanish', languageCode: 'es' },
  { label: 'French', languageCode: 'fr' },
  { label: 'Portuguese', languageCode: 'pt' },
  { label: 'Russian', languageCode: 'ru' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
const TOKEN = 'authtoken'
const LANGUAGE = 'operator_language'
const WALLETSETTING = 'operator_walletSetting'
const OPERATOR_ID = 'operator_id'
const ROLE = 'operator_role'
const DEFAULT_LIVE_STATS = { profit: 0, wins: 0, wagered: 0, losses: 0 }

/* ==========================================================================
Operator Roles
========================================================================== */
const OPERATOR_ROLES = {
  operator: 'OPERATOR',
  operatorUser: 'OPERATOR_USER'
}

/* ==========================================================================
Loader types
========================================================================== */
const LOADER = {
  BUTTON: 'button',
  CONTENT: 'content',
  TABLE_CELL: 'tableCell'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Loader Types
========================================================================== */
const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  Table Cell Type
========================================================================== */
const TABLE_CELL_TYPE = {
  status: 'status',
  switch: 'switch',
  button: 'button',
  date: 'date',
  text: 'text',
  number: 'number',
  default: 'default',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */

const ROUTE_PATHS = {
  HOME: '/',
  HOME_SIGNUP: '/signup',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FEEDBACK: '/user/feedback',
  SUBMITFEEDBACK: '/user/feedback-submit',
  PARTNERPROGRAM: '/user/become-partner',
  PARTNER_TYPE: '/user/become-partner/partner/:id',
  CRASH_GAME: '/casino/game/crash-game',
  DICE_GAME: '/casino/game/dice-game',
  DICE_GAME_DEMO: '/dice-game/demo',
  MINE_GAME: '/mine-game',
  VERIFY_EMAIL: '/verify/:userId/:verificationToken',
  RESET_PASSWORD: '/reset-password/:userId/:resetToken',
  ABOUT: '/about',
  SITE_INFO: '/site-info/:pageName',
  GAME_CATAGORY: '/casino/games/:gameCategory',
  FAV_CATAGORY: '/games/favorites',
  TERMS_AND_CONDITION: '/terms-condition',
  SPORTS_BOOK: '/sports-book',
  CASINO_PLAYGAME: '/casino/play-game/:gameId/:gameCode',
  CASINO: '/casino',
  SETTINGS: '/settings',
  KYC: '/kyc',
  TRANSACTIONS: '/transactions',
  BLACKJACKGAME: '/casino/game/blackjack',
  ROULETTE: '/casino/game/roulette',
  RECENTLY_PLAYED: '/games/recently-played',
  PROFILE: {
    ROOT: '/profile',
    BET_HISTORY: '/profile/bet-history',
    TRANSACTIONS: '/profile/transactions',
    DEPOSIT: '/profile/deposit',
    CASHOUT: '/profile/cashout',
    GAME_LIMITS: '/profile/game-limits',
    REFERRAL: '/profile/referral',
    CHANGE_PASSWORD: '/profile/change-password'
  }
}

/* ==========================================================================
  DIALOG Types
========================================================================== */
const DIALOG_TYPE = {
  add: 'add',
  update: 'update',
  delete: 'delete',
  hide: ''
}

const DEFAULT_GAME_ID = {
  CRASH: '1',
  DICE: '2',
  BLACKJACK: '3',
  ROULETTE: '4'
}
const MAX_BET_COUNT = {
  CRASH: 1
}
const MAX_INCREASED_BY_PERSENTAGE = {
  CRASH: 1
}
const MIN_PAYOUT = {
  CRASH: 1
}
const DEFAULT_SPORTS_BOOK_GAME_ID = 3

const BET_RESULT = {
  WON: 'won',
  LOST: 'lost',
  CANCELLED: 'cancelled'
}

const TABLE_TABS = {
  AFTER_HOURS_ORIGINALS: 'after_hours_originals',
  VIVA_ORIGINALS: 'viva-originals',
  MY_BETS: 'my-bets',
  CASINO: 'casino',
  SPORTS_BOOK: 'sports-book'
}

export const CUSTOM_GAMES_ICONS = {
  dice: '/assets/images/dice/dice-icon.svg',
  crash: '/assets/images/casino/crash-table.svg'
}

export const PAGINATION_LIMIT = 10
export const PAGINATION_LIMIT_FOR_ALLBETS = 30
export const DEFAULT_PAGE_CALLS = 10
export const MIN_WITHDRAW_AMOUNT = 0.003
export const WITHDRAW_FEES = 0.000006
export const TAB_SELECTION = Object.freeze({
  placed: Symbol('placed'),
  previous: Symbol('previous')
})

export const BET_MODES = {
  MANUAL: 'manual',
  AUTO: 'auto',
  STRATEGY: 'strategy'
}

// Default dice strategies
export const defaultDiceStrategies = [
  {
    label: 'Martingale',
    isDefault: true,
    blocks: [
      {
        id: 'mart1',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'increaseByPercentage',
          value: 100
        }
      },
      {
        id: 'mart2',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'win'
        },
        do: {
          type: 'increaseByPercentage',
          value: 0
        }
      }
    ]
  },
  {
    label: 'Delayed Martingale',
    isDefault: true,
    blocks: [
      {
        id: 'delay1',
        type: 'bets',
        on: {
          type: 'streakGreaterThan',
          value: 3,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'increaseByPercentage',
          value: 100
        }
      },
      {
        id: 'delay2',
        type: 'bets',
        on: {
          type: 'firstStreakOf',
          value: 3,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'increaseByPercentage',
          value: 100
        }
      },
      {
        id: 'delay3',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'win',
          profitType: 'balance'
        },
        do: {
          type: 'resetAmount',
          value: 0
        }
      }
    ]
  },
  {
    label: 'Paroli',
    isDefault: true,
    blocks: [
      {
        id: 'paroli1',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'win',
          profitType: 'balance'
        },
        do: {
          type: 'increaseByPercentage',
          value: 100
        }
      },
      {
        id: 'paroli2',
        type: 'bets',
        on: {
          type: 'streakGreaterThan',
          value: 3,
          betType: 'win',
          profitType: 'balance'
        },
        do: {
          type: 'resetAmount',
          value: 0
        }
      },
      {
        id: 'paroli3',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'resetAmount',
          value: 0
        }
      }
    ]
  },
  {
    label: "D 'Alembert",
    isDefault: true,
    blocks: [
      {
        id: 'Alembert1',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'win',
          profitType: 'balance'
        },
        do: {
          type: 'addToAmount',
          value: 1e-8
        }
      },
      {
        id: 'Alembert2',
        type: 'bets',
        on: {
          type: 'every',
          value: 1,
          betType: 'lose',
          profitType: 'balance'
        },
        do: {
          type: 'subtractFromAmount',
          value: 1e-8
        }
      }
    ]
  }
]

const LANGUAGE_LIST = [
  {
    label: 'English',
    code: 'en',
    img: '/assets/images/stock-images/united-kingdom.jpg'
  },
  {
    label: 'Spanish',
    code: 'es',
    img: '/assets/images/stock-images/spain-flag.png'
  },
  {
    label: 'Portuguess',
    code: 'pt',
    img: '/assets/images/stock-images/portugal-flag.png'
  },
  {
    label: 'Italian',
    code: 'it',
    img: '/assets/images/stock-images/italy-flag.png'
  },
  {
    label: 'French',
    code: 'fr',
    img: '/assets/images/stock-images/france-flag.png'
  }
]

// Export All constant variables
export {
  LANGUAGES,
  TOASTER_TYPE,
  TOKEN,
  LANGUAGE,
  OPERATOR_ID,
  ROLE,
  OPERATOR_ROLES,
  ROUTE_PATHS,
  LOADER,
  METHOD_TYPES,
  LOADER_HANDLER_TYPES,
  BET_RESULT,
  TABLE_CELL_TYPE,
  DIALOG_TYPE,
  WALLETSETTING,
  DEFAULT_GAME_ID,
  DEFAULT_SPORTS_BOOK_GAME_ID,
  TABLE_TABS,
  DEFAULT_LIVE_STATS,
  MAX_BET_COUNT,
  MAX_INCREASED_BY_PERSENTAGE,
  MIN_PAYOUT,
  LANGUAGE_LIST
}

export const PartnerProgramsTabs = [
  {
    id: 'investor',
    name: 'Become An Investor',
    path: 'investor'
  },
  {
    id: 'affiliate',
    name: 'Become An Affiliate',
    path: 'affiliate'
  }
]
