import React from 'react'
import { useTranslation } from 'react-i18next'

const Popup = ({ handleClose, content }) => {
  const { t } = useTranslation('home')

  return (

    <div className='modal fade show' style={{ display: 'block' }} id='popup_modal'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          {/* <!-- MODAL BODY --> */}
          <div className='modal-body'>
            {/* {(signupLoading) && (
                <Loader />
              )} */}

            <div className='content-box'>
              <button
                style={{ float: 'right' }}
                type='button'
                className='btn-close rounded-circle border border-secondary btn-close-white '
                onClick={() => handleClose(false)}
              />
              <div className='row g-0 p-4'>
                <div className='col-md-12'>
                  <div className='side-content-box'>
                    <h1 className='modal-title'>{t('signup.title')}</h1>
                    <h5 className='modal-body'>{content} </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}

export default Popup
