import { createAsyncThunk } from '@reduxjs/toolkit'
import { getDiceGameBetHistory, diceGamePlaceBet, addCustomFavourite, removeCustomFavourite, getDiceGameTopBets, diceCheckFairness } from '../../../../network/services/dice'
import { setDemoWallet } from '../../../redux-slices/gameSetting'
import { setDiceBetHistory, setDiceLossCount, setDiceWinsCount, setProfit, setWagered } from '../../../redux-slices/Games/diceGame'
import { getAllCustomFavouriteGamesService } from '../../../../network/services/sportsBook.service'
import { setCustomFavGames, setLiveStatsData, setUpdateSystemMyBet } from '../../../redux-slices/games'
import { BET_RESULT } from '../../../../utils/constants'
import { setLoseStreakCount, setStrategyBetCount, setStrategyLoseCount, setStrategyWinCount, setWinStreakCount, setDiceStrategyResultArray } from '../../../redux-slices/strategy'

export const getDiceGameBetRecord = createAsyncThunk(
  'diceGame/get-bet-history', async ({ addToast, limit }, thunkApi) => {
    try {
      const res = await getDiceGameBetHistory({ limit, offset: 0 })
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const diceGamePlacedBet = createAsyncThunk(
  'diceGame/place-bet', async ({ payload, addToast, isDemoGame }, thunkApi) => {
    try {
      const res = await diceGamePlaceBet({ data: payload, isDemoGame })
      const { diceBetHistory, wagered, profit } = thunkApi?.getState()?.diceGame
      const { isStrategyMode, strategyResultArray } = thunkApi?.getState()?.strategy
      let newBetHistory = {}
      // let rows = [{ id: res?.id, result: res?.result, winningNumber: res?.winningNumber }]
      let rows
      if (diceBetHistory) {
        rows = [res, ...diceBetHistory?.rows]
      }
      newBetHistory = { count: diceBetHistory?.count ? diceBetHistory?.count + 1 : 1, rows }
      thunkApi.dispatch(setDiceBetHistory(newBetHistory))
      thunkApi.dispatch(setDemoWallet(res?.demoAmount))
      const { result, betAmount, winningAmount } = res || {}
      thunkApi.dispatch(setLiveStatsData({ result, betAmount, winningAmount }))
      thunkApi.dispatch(setUpdateSystemMyBet({ latestBets: [{ ...res, game: { name: 'dice' } }] }))
      if (res?.result) {
        thunkApi.dispatch(setDiceStrategyResultArray([...strategyResultArray, res?.result]))
      }
      if (isStrategyMode) {
        if (res?.result === BET_RESULT.WON) {
          thunkApi.dispatch(setWinStreakCount())
          thunkApi.dispatch(setLoseStreakCount({ reset: 0 }))
          thunkApi.dispatch(setStrategyWinCount())
          thunkApi.dispatch(setStrategyBetCount())
        } else {
          thunkApi.dispatch(setLoseStreakCount())
          thunkApi.dispatch(setWinStreakCount({ reset: 0 }))
          thunkApi.dispatch(setStrategyLoseCount())
          thunkApi.dispatch(setStrategyBetCount())
        }
      }

      if (res?.result === BET_RESULT.WON) {
        thunkApi.dispatch(setDiceWinsCount())
        thunkApi.dispatch(setProfit((profit + parseFloat(res?.winningAmount) - parseFloat(res?.betAmount))))
        thunkApi.dispatch(setWagered(wagered + parseFloat(res?.betAmount)))
      } else {
        thunkApi.dispatch(setDiceLossCount())
        thunkApi.dispatch(setWagered(wagered + parseFloat(res?.betAmount)))
      }

      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const addCustomFav = createAsyncThunk(
  'add-diceGame-fav', async ({ addToast, customGameId }, thunkApi) => {
    try {
      const res = await addCustomFavourite({ customGameId })
      const { customFavourites } = thunkApi?.getState()?.games
      thunkApi.dispatch(setCustomFavGames({ ...customFavourites, rows: [...customFavourites.rows, { customGameId }] }))
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const removeCustomFav = createAsyncThunk(
  'remove-diceGame-fav', async ({ addToast, customGameId }, thunkApi) => {
    try {
      const res = await removeCustomFavourite({ customGameId })
      const { customFavourites } = thunkApi?.getState()?.games
      thunkApi.dispatch(setCustomFavGames({ ...customFavourites, rows: [...customFavourites?.rows?.filter(game => game?.customGameId !== customGameId)] }))
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const getAllCustomFavouriteGames = createAsyncThunk(
  'fetch/custom-favourite-games-list',
  async (data, thunkApi) => {
    try {
      const res = await getAllCustomFavouriteGamesService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getDiceGameHighRollerTransactions = createAsyncThunk(
  'dice-game/high-roller',
  async (data, thunkApi) => {
    try {
      const res = await getDiceGameTopBets(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const checkFairnessDiceGame = createAsyncThunk('dice-game/check-fairness', async ({ payload, addToast }, thunkApi) => {
  try {
    console.log('payload', payload)
    const res = await diceCheckFairness(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})
