import React from 'react'

import { UserIcon } from '../../icons'
import { GameIconMapping } from '../../utils/gameIcon'
import { capitalize } from 'lodash'
import { LOADER } from '../../utils/constants'
import Loader from '../../components/Loader'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const BetsTable = ({ selectedtab, currentTab, betsToShow, betLoading }) => {
  const isMyBet = currentTab === 'myBets'
  const { t } = useTranslation('home')
  const { allCurrencies } = useSelector(state => state.gameSetting)

  return (
    <div
      className={`tab-pane fade show ${selectedtab === currentTab ? 'active' : ''
        }`}
      id='lobby'
      role='tabpanel'
    >
      <div className='table-wrap'>
        <div className='table-responsive'>
          <table className='table table-striped table-default'>
            <thead>
              <tr>
                {!isMyBet && (
                  <th scope='col'>
                    {t('betsTable.sideBetTableHeading.player')}
                  </th>)}
                <th scope='col' className='text-start ps-4'><span className='ms-1'>{t('betsTable.sideBetTableHeading.game')}</span></th>
                {isMyBet && (
                  <th scope='col' className='text-start ps-4'>
                    <span className='ms-1'>
                      {t('betsTable.sideBetTableHeading.bet')}
                    </span>
                  </th>)}
                <th scope='col'>
                  {t('betsTable.sideBetTableHeading.multiplier')}
                </th>
                <th scope='col'>
                  {t('betsTable.sideBetTableHeading.win')}
                </th>
              </tr>
            </thead>
            <tbody>
              {betLoading
                ? (
                  <tr className='text-center'>
                    <td colSpan='6'><Loader component={LOADER.TABLE_CELL} /></td>
                  </tr>
                  )
                : betsToShow?.length
                  ? betsToShow
                      .map((bet) => {
                        const coinObj = allCurrencies.find(coin => coin.id === bet.currencyId)?.code?.toLowerCase() || ''
                        return (
                          <tr id={bet.createdAt} key={bet.createdAt}>
                            {!isMyBet && (
                              <td>
                                <div className='text-box'>
                                  <span className='icon'>
                                    <UserIcon />
                                  </span>
                                  <span className='text'>{capitalize(bet?.user?.userName || '')}</span>
                                </div>
                              </td>
                            )}
                            <td>
                              <div className='text-box justify-content-start'>
                                <span className='icon'>
                                  {GameIconMapping(bet?.game?.name)}
                                </span>
                                <span className='text'>AH {capitalize(bet?.game?.name || '')}</span>
                              </div>
                            </td>
                            {isMyBet && (
                              <td>
                                <div className='text-box justify-content-center'>
                                  <span className='text'>
                                    {bet?.insuranceResult ? parseFloat(bet?.insuranceBetAmount).toFixed(2) : (+bet?.betAmount).toFixed(2) || '0.00'}
                                  </span>
                                  <span className='icon'>
                                    <img
                                      src={`/assets/images/currency/currency-${coinObj || 'default'}.png`}
                                      className='img-fluid'
                                      alt='Icon'
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                  </span>
                                </div>
                              </td>
                            )}
                            <td>
                              <div className='text-box justify-content-center'>
                                <span className='text'>
                                  {bet?.insuranceResult
                                    ? (bet?.insuranceBetAmount ? parseFloat(parseFloat(bet?.insuranceWinAmount) / parseFloat(bet?.insuranceBetAmount)).toFixed(2) : '0.00')
                                    : (bet?.betAmount ? parseFloat(+bet?.winningAmount / +bet?.betAmount).toFixed(2) : '0.00')}x
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className='text-box justify-content-center'>
                                <span className='text'>{parseFloat(bet?.insuranceResult ? bet?.insuranceBetAmount : bet?.winningAmount).toFixed(2)}</span>
                                <span className='icon'>
                                  <img
                                    src={`/assets/images/currency/currency-${coinObj || 'default'}.png`}
                                    className='img-fluid'
                                    alt='Icon'
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                  : (
                    <tr className='text-center'>
                      <td colSpan='6'>
                        {t('betsTable.noData')}
                      </td>
                    </tr>
                    )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BetsTable
