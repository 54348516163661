import { createAsyncThunk } from '@reduxjs/toolkit'
import { setFavouriteGame } from '../../redux-slices/games'
import { getGamesListService, getCasinoProvidersService, getCasinoAllCategoriesService, getUsersCasinoTransactionsService, removeFavouriteGameService, addFavouriteGameService, getAllFavouriteGamesService, getCustomGameTransactionsService, getCasinoTransactionsService, getSportsBookTransactionsService, getCustomGameHighRollerTransactionsService, getMyCustomGameTransactionsService, addCustomFavouriteGameService, removeCustomFavouriteGameService, getRecentlyPlayedGamesService } from '../../../network/services/sportsBook.service'
import { getCustomGameDetailsService } from '../../../network/services/gamesetting'
import { setSelectedCasinoGame } from '../../redux-slices/Games/casinoGame'

export const getCasinoGamesList = createAsyncThunk(
  'fetch/casino-category-games',
  async ({ data }, thunkApi) => {
    try {
      const res = await getGamesListService({ ...data })
      const { categoryWiseGamesList } = thunkApi?.getState()?.games
      const updatedList = [...categoryWiseGamesList, { type: (data?.type), ...res }]
      return updatedList
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllGames = createAsyncThunk(
  'fetch/games-list',
  async ({ data }, thunkApi) => {
    try {
      let res = await getGamesListService({ ...data })
      if (data?.offset > 0) {
        const { viewAllGames } = thunkApi?.getState()?.games
        res = { count: res?.count, rows: [...viewAllGames?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCasinoProvidersList = createAsyncThunk(
  'fetch/providers-list',
  async (data, thunkApi) => {
    try {
      let res = await getCasinoProvidersService(data)
      if (data?.offset > 0) {
        const { providers } = thunkApi?.getState()?.games
        res = { count: res?.count, rows: [...providers?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCasinoAllCategories = createAsyncThunk(
  'fetch/all-categories-list',
  async (_, thunkApi) => {
    try {
      const res = await getCasinoAllCategoriesService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUsersCasinoTransactions = createAsyncThunk(
  'fetch/users-casino-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getUsersCasinoTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllFavouriteGames = createAsyncThunk(
  'fetch/favourite-games-list',
  async ({ data }, thunkApi) => {
    try {
      let res = await getAllFavouriteGamesService(data)
      if (data?.offset > 0) {
        const { favouriteGames } = thunkApi?.getState()?.games
        res = { count: res?.count, rows: [...favouriteGames?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const addFavouriteCustomGame = createAsyncThunk(
  'fetch/add-favourite-games',
  async ({ game }, thunkApi) => {
    try {
      const res = await addCustomFavouriteGameService({ customGameId: game?.id })
      const { favouriteGames } = thunkApi?.getState()?.games
      const addFavGame = { ...favouriteGames, customGames: { count: favouriteGames?.customGames?.count + 1, rows: [{ Game: { ...game } }, ...favouriteGames.customGames.rows] } }
      thunkApi?.dispatch(setFavouriteGame(addFavGame))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const removeFavouriteCustomGame = createAsyncThunk(
  'fetch/remove-favourite-game',
  async ({ game }, thunkApi) => {
    try {
      const res = await removeCustomFavouriteGameService({ customGameId: game?.id })
      const { favouriteGames } = thunkApi?.getState()?.games
      const removeFavGame = { ...favouriteGames, customGames: { count: favouriteGames?.customGames?.count - 1, rows: favouriteGames?.customGames?.rows?.filter(ele => ele?.Game?.id !== game?.id) } }
      thunkApi?.dispatch(setFavouriteGame(removeFavGame))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const addFavouriteGame = createAsyncThunk(
  'fetch/add-favourite-games',
  async ({ game, gameData }, thunkApi) => {
    try {
      const res = await addFavouriteGameService({ gameId: game?.id })
      const { favouriteGames } = thunkApi?.getState()?.games
      const addFavGame = { ...favouriteGames, casinoGames: { count: favouriteGames?.casinoGames?.count + 1, rows: [{ CasinoGame: { ...gameData } }, ...favouriteGames.casinoGames.rows] } }
      thunkApi?.dispatch(setFavouriteGame(addFavGame))
      thunkApi?.dispatch(setSelectedCasinoGame({ ...gameData, isFavourite: true }))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const removeFavouriteGame = createAsyncThunk(
  'fetch/remove-favourite-game',
  async ({ game, gameData }, thunkApi) => {
    try {
      const res = await removeFavouriteGameService({ gameId: game?.id })
      const { favouriteGames } = thunkApi?.getState()?.games
      const removeFavGame = { ...favouriteGames, casinoGames: { count: favouriteGames?.casinoGames?.count - 1, rows: favouriteGames?.casinoGames?.rows?.filter(ele => ele?.CasinoGame?.id !== game?.id) } }
      thunkApi?.dispatch(setFavouriteGame(removeFavGame))
      thunkApi?.dispatch(setSelectedCasinoGame({ ...gameData, isFavourite: false }))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCustomGameTransactions = createAsyncThunk(
  'fetch/custom-game-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getCustomGameTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCustomGameHighRollerTransactions = createAsyncThunk(
  'fetch/custom-game-transactions-high-roller',
  async (data, thunkApi) => {
    try {
      const res = await getCustomGameHighRollerTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyCustomGameTransactions = createAsyncThunk(
  'fetch/my-custom-game-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getMyCustomGameTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCasinoTransactions = createAsyncThunk(
  'fetch/get-casino-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getSportsBookTransactions = createAsyncThunk(
  'fetch/get-sports-book-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getSportsBookTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCustomGameDetails = createAsyncThunk(
  'system/get-game-details',
  async (_, thunkApi) => {
    try {
      const res = await getCustomGameDetailsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getRecentlyPlayedGames = createAsyncThunk(
  'fetch/recent-played-games-list',
  async (params, thunkApi) => {
    try {
      const res = await getRecentlyPlayedGamesService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
