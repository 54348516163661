import { METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis'

export const getDiceGameBetHistory = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'dice-game/my-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const diceGamePlaceBet = ({ data, isDemoGame }) => {
  return axiosInstance(METHOD_TYPES.post, 'dice-game/place-bet', data, {
    server: microServices.USER_URL,
    headers: { 'demo-game': isDemoGame }
  })
}

export const addCustomFavourite = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'common-game/add-custom-favourite', data, {
    server: microServices.USER_URL
  })
}

export const removeCustomFavourite = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'common-game/remove-custom-favourite', data, {
    server: microServices.USER_URL
  })
}

export const getDiceGameTopBets = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'dice-game/top-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const diceCheckFairness = ({ data }) => {
  console.log('data', data)
  return axiosInstance(METHOD_TYPES.post, 'dice-game/check-fairness', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}
