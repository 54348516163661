import React, { memo } from 'react'
import { LOADER } from '../../utils/constants'
const Loader = ({ component }) => {
  switch (component) {
    case LOADER.CONTENT:
      return (
        <div className='vh-100 vw-100 d-flex justify-content-center align-items-center py-2'>
          <div className='spinner-border text-light mt-2 mb-2' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )

    case LOADER.TABLE_CELL:
      return <span className='spinner-border spinner-border-sm text-light' role='status' aria-hidden='true' />

    case LOADER.BUTTON:
      return <span className='spinner-border spinner-border-sm text-light' role='status' aria-hidden='true' />

    default:
      return (
        // <div className='d-flex justify-content-center spinner-border show-loader' role='status' />
        <div className='loader-wrap'>
          <div className='loader'>
            <div className='spin-blend' />
            <div className='spin-blend' />
            <div className='spin-blend' />
            <div className='spin-blend' />
            <div className='spin-blend' />
          </div>
          <div className='loading-text'>
            <div className='letter'>L</div>
            <div className='letter'>o</div>
            <div className='letter'>a</div>
            <div className='letter'>d</div>
            <div className='letter'>i</div>
            <div className='letter'>n</div>
            <div className='letter'>g</div>
            <div className='letter'>.</div>
            <div className='letter'>.</div>
            <div className='letter'>.</div>
          </div>
        </div>
      )
  }
}

export default memo(Loader)
