import { createAsyncThunk } from '@reduxjs/toolkit'
import { blackJackCheckFairness, blackJackGameDoubleBet, blackJackGameHitBet, blackJackGamePlaceBet, blackJackGameSplitBet, blackJackGameStandBet, blackJackPlaceInsuranceBet, blackJackUnfinishedBet, blackJackUpdateCurrentHandStatus, getBlackJackGameBetHistory, getBlackJackHighRollerTransactionsService, getBlackJackTransactionsService, getMyBlackJackTransactionsService } from '../../../../network/services/blackjack.service'

export const placedBetBlackjackGame = createAsyncThunk('blackjack-game/place-bet-blackjack', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await blackJackGamePlaceBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    addToast(('Bet Placed Successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const hitBetBlackjackGame = createAsyncThunk('blackjack-game/hit-bet-blackjack', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await blackJackGameHitBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const standBetBlackjackGame = createAsyncThunk('blackjack-game/stand-bet-blackjack', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await blackJackGameStandBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const splitBetBlackjackGame = createAsyncThunk('blackjack-game/split-bet-blackjack', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await blackJackGameSplitBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const doubleBetBlackjackGame = createAsyncThunk('blackjack-game/double-bet-blackjack', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await blackJackGameDoubleBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const betListBlackjackGame = createAsyncThunk('blackjack-game/double-bet-blackjack', async ({ params, addToast }, thunkApi) => {
  try {
    const res = await getBlackJackGameBetHistory(params)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const unfinishedBetBlackjackGame = createAsyncThunk('blackjack-game/unfinished-bet-blackjack', async ({ addToast }, thunkApi) => {
  try {
    const res = await blackJackUnfinishedBet()
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const placeInsuranceBlackjackGame = createAsyncThunk('blackjack-game/place-insurance', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await blackJackPlaceInsuranceBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const updateCurrentStatusBlackjackGame = createAsyncThunk('blackjack-game/update-current-hand-status', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await blackJackUpdateCurrentHandStatus(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const checkFairnessBlackjackGame = createAsyncThunk('blackjack-game/check-fairness', async ({ payload, addToast }, thunkApi) => {
  try {
    const res = await blackJackCheckFairness(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // addToast(('Bet Placed successfully'), { appearance: 'success' })
    return res
  } catch (error) {
    addToast(error[0].description, { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getBlackJackHighRollerTransactions = createAsyncThunk(
  'fetch/blackjack-transactions-high-roller',
  async (data, thunkApi) => {
    try {
      const res = await getBlackJackHighRollerTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBlackJackTransactions = createAsyncThunk(
  'fetch/blackjack-game-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getMyBlackJackTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getBlackJackTransactions = createAsyncThunk(
  'fetch/get-blackjack-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getBlackJackTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
