import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { handleLanguageChange } from '../../redux-store/redux-slices/Auth/authSlice'
import { LANGUAGE_LIST } from '../../utils/constants/index'

function LanguageSwitcher () {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const { language } = useSelector(
    (state) => state.authSlice
  )

  const handleLanguageSwitch = (code) => {
    i18n.changeLanguage(code)
    dispatch(handleLanguageChange(code))
  }

  const currentLang = useMemo(() => {
    return LANGUAGE_LIST.find(lang => lang.code === language)
  }, [language])

  return (
    <>
      <div className='dropdown language-dropdown mx-md-auto me-auto'>
        <button className='btn text-btn' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
          <span className='icon' style={{ marginRight: '10px', width: '10px' }}>
            <img src={currentLang.img} className='img-fluid' alt='Icon' />
          </span>
          <span className='text d-none d-md-block'>{currentLang.label}</span>
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
          {LANGUAGE_LIST.map(lang => (
            <li key={lang.code} onClick={() => handleLanguageSwitch(lang.code)}>
              <a className='dropdown-item'>
                <span className='icon'>
                  <img src={lang.img} className='img-fluid' alt='Icon' />
                </span>
                <span className='text'>{lang.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default LanguageSwitcher
