import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis/index'

export const getAllCurrencyService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-all-currency', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

// export const updateCurrencySettingService = (data) => {
//   return axiosInstance(METHOD_TYPES.post, 'user/set-primary-wallet', data, {
//     server: microServices.USER_URL,
//     loader: LOADER_HANDLER_TYPES.table
//   })
// }

export const getGameSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-game-settings', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getCustomGameDetailsService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-game-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getAllSiteinfoService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-all-cms', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getGameThemeSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-all-game-theme-settings', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getEncourageWordsSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, 'crash-game/list-uplifting-words', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const setUpdateAffilateCountService = (data) => {
  return axiosInstance(METHOD_TYPES.put, 'system/increase-affiliateCount', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getBannerService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-banners', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getSponserService = () => {
  return axiosInstance(METHOD_TYPES.get, '/sponsor/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getGameImagesService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/list-game-images', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getListWonderGirlsService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/list-wonder-girls', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
