import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorMessage from '../../../components/ErrorMessage'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { withdrawRequest } from '../../../redux-store/redux/thunk/Users/User'

export const Withdraw = ({ setShowWalletPopup }) => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const schema = yup.object().shape({
    amount: yup.number()
      .typeError('Amount is required')
      .required('Amount is required')
      .moreThan(1, 'Deposit amount must be greater than 1'),
    accountType: yup.string().required('Account Type is required'),
    accountNumber: yup.string()
      .when('accountType', {
        is: (val) => val === 'PHONE',
        then: yup.string()
          .length(11, 'Mobile number must be of 11 digits')
          .required('Phone is required'),
        otherwise: yup.string().notRequired()
      })
      .when('accountType', {
        is: (val) => val === 'EMAIL',
        then: yup.string()
          .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email is not valid')
          .required('Email is required'),
        otherwise: yup.string().notRequired()
      })
      .when('accountType', {
        is: (val) => val === 'CPF',
        then: yup.string()
          .length(11, 'CPF must be of 11 characters')
          .required('CPF is required'),
        otherwise: yup.string().notRequired()
      })
      .when('accountType', {
        is: (val) => val === 'CNPJ',
        then: yup.string()
          .length(14, 'CNPJ must be of 14 characters')
          .required('CNPJ is required'),
        otherwise: yup.string().notRequired()
      }),
    customerCertification: yup.string()
      .required('Customer Certification is required')
      .length(11, 'Customer Certification must be of 11 characters')
  })

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const handleDepositSubmit = (values) => {
    dispatch(withdrawRequest({ values, addToast }))
    setShowWalletPopup(false)
  }
  return (
    <div className='tab-pane fade text-white' id='pills-withdraw' role='tabpanel' aria-labelledby='pills-profile-tab'>
      <form onSubmit={handleSubmit(handleDepositSubmit)}>
        <div className='row g-2'>
          <div className='form-group required col-md-12 d-flex mb-2 flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='amount'>Amount</label>
            <input
              type='number'
              className='form-control'
              id='amount'
              name='amount'
              autoComplete='off'
              maxLength={20}
              {...register('amount')}
            />
            {errors && errors.amount && (
              <ErrorMessage
                className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                message={errors.amount.message}
              />
            )}
          </div>
          <div className='form-group required col-md-12 d-flex mb-2 flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='amount'>Account Type</label>
            <select
              className='form-control form-select'
              name='accountType'
              id='accountType'
              aria-label='Default select example'
              {...register('accountType')}
            >
              <option value='PHONE'>Phone</option>
              <option value='CPF'>CPF</option>
              <option value='CNPJ'>CNPJ</option>
              <option value='EMAIL'>Email</option>
            </select>
          </div>
          <div className='form-group required col-md-12 d-flex mb-2 flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='amount'>Account Number</label>
            <input
              type='text'
              className='form-control'
              id='accountNumber'
              name='accountNumber'
              autoComplete='off'
              maxLength={20}
              {...register('accountNumber')}
            />
            {errors && errors.accountNumber && (
              <ErrorMessage
                className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                message={errors.accountNumber.message}
              />
            )}
          </div>
          <div className='form-group required col-md-12 d-flex mb-2 flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='customerCertification'>Customer Certification (CPF)</label>
            <input
              type='text'
              className='form-control'
              id='customerCertification'
              name='customerCertification'
              autoComplete='off'
              maxLength={11}
              {...register('customerCertification')}
            />
            {errors && errors.customerCertification && (
              <ErrorMessage
                className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                message={errors.customerCertification.message}
              />
            )}
          </div>
          <div>
            <button
              type='submit'
              className='btn btn-primary mt-2 mb-3'
              style={{ width: '100%', lineHeight: '40px' }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
