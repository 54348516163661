import React, { memo, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown
} from '@fortawesome/free-solid-svg-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ErrorMessage from '../../ErrorMessage'
import { withdrawRequest } from '../../../redux-store/redux/thunk/Users/User'
import Loader from '../../Loader'
import { LOADER } from '../../../utils/constants'

const WithdrawSection = ({ selectedCurrency, cryptoCurrencies, primaryCurrency, handleCurrencyChange, conversionState, selectedTab, handleConversion, selectedWallet }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const withdrawValidationSchema = yup.object().shape({
    withdrawalAddress: yup
      .string()
      .required('Please enter your wallet address.'),
    withdrawalAmountUSD: yup
      .number()
      .required('Please specify the amount to be withdrawn')
      .min(0, 'Invalid value for amount')
      .max(selectedWallet?.amount || 0, 'Insufficient Balance')
  })

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(withdrawValidationSchema),
    mode: 'all'
  })

  useEffect(() => {
    if (selectedTab && isDirty) {
      reset()
    }
  }, [selectedTab])

  const handleWithdrawSubmit = async (formData) => {
    if (+formData.withdrawalAmount > 0 && formData?.withdrawalAddress && selectedCurrency?.id) {
      const values = {
        withdrawalAmount: +formData.withdrawalAmount,
        withdrawalAddress: formData.withdrawalAddress,
        walletId: +selectedCurrency?.id
      }
      setWithdrawLoading(true)
      await dispatch(withdrawRequest({ values, reset, addToast }))
      setWithdrawLoading(false)
    }
  }

  const handleOnConversionValueChange = async (e, isFiat) => {
    const { fiatAmount, cryptoAmount } = await handleConversion(e, isFiat)
    if (fiatAmount > +selectedWallet?.amount) {
      setError('withdrawalAmountUSD', { type: 'custom', message: 'Insufficient Balance' })
    } else if (errors?.withdrawalAmountUSD) clearErrors('withdrawalAmountUSD')
    if (isFiat) setValue('withdrawalAmount', cryptoAmount)
    else setValue('withdrawalAmountUSD', fiatAmount)
  }

  return (
    <div className='tab-pane fade' id='withdraw' role='tabpanel' aria-labelledby='withdraw-tab'>
      {/* FIRST CONTENT WRAP START */}
      <div className='content-wrap'>
        <div className='content-box'>
          <div className='row justify-content-center'>
            <div className='col-12'>
              <div className='dropdown mb-3'>
                <label className='form-label'>Currency</label>
                <button className='btn primary-btn w-100' type='button' id='currencyDropdown' data-bs-toggle='dropdown' aria-expanded='false'>
                  <span className='icon'>
                    <img src={`/assets/images/currency/currency-${selectedCurrency?.currency?.code.toLowerCase() || 'default'}.png`} className='img-fluid' alt='Icon' />
                  </span>
                  <span className='text'>{selectedCurrency?.currency?.code}</span>
                  <FontAwesomeIcon icon={faAngleDown} className='ms-auto' />
                </button>
                <ul className='dropdown-menu' aria-labelledby='currencyDropdown'>
                  {cryptoCurrencies && Object.keys(cryptoCurrencies)?.map(crypto =>
                    <li key={crypto}>
                      <a href='#' className='dropdown-item' onClick={() => handleCurrencyChange(cryptoCurrencies[crypto])}>
                        <div className='d-flex justify-content-between align-items-center'>
                          {/* <span className='text'>0.00000000</span> */}
                          <span className='d-flex align-items-center ms-3'>
                            <span className='icon me-2'>
                              <img src={`/assets/images/currency/currency-${crypto.toLowerCase() || 'default'}.png`} className='img-fluid' alt='Icon' />
                            </span>
                            <span className='text'>{crypto}</span>
                          </span>
                        </div>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <form onSubmit={handleSubmit(handleWithdrawSubmit)}>
              <div className='col-sm-12'>
                <label className='form-label'>
                  <img src={`/assets/images/currency/currency-${selectedCurrency?.currency?.code.toLowerCase() || 'default'}.png`} className='img-fluid me-2' alt='Icon' />
                  {selectedCurrency?.currency?.code} Address <span className='text-danger ms-1'>*</span>
                </label>
                <div className='input-group mb-2'>
                  <input type='text' className='form-control' placeholder={`Enter Your ${selectedCurrency?.currency?.code} Address `} {...register('withdrawalAddress')} />
                </div>
                {errors && errors.withdrawalAddress && (
                  <ErrorMessage
                    className='text-danger error-msg login-input small mx-2'
                    message={errors.withdrawalAddress.message}
                  />
                )}
              </div>
              <div className='col-12 mt-3'>
                <div className='d-flex justify-content-between gap-3'>
                  <div className='form-group mb-3'>
                    <label className='form-label mx-1'>{selectedCurrency?.currency?.code}</label>
                    <input type='number' style={{ '--input-coin-img': `url('/assets/images/currency/currency-${selectedCurrency?.currency?.code.toLowerCase() || 'default'}.png')` }} className='form-control input-coin' {...register('withdrawalAmount')} value={conversionState?.cryptoAmount || 0} onChange={(e) => handleOnConversionValueChange(e, false)} />
                  </div>
                  <div className='align-self-end'>
                    <h3 className='mb-4 text-white'>=</h3>
                  </div>
                  <div className='form-group mb-3'>
                    <label className='form-label mx-1'>{primaryCurrency}</label>
                    <input type='number' style={{ '--input-coin-img': `url('/assets/images/currency/currency-${primaryCurrency.toLowerCase() || 'default'}.png')` }} className='form-control input-coin' {...register('withdrawalAmountUSD')} value={conversionState?.fiatAmount || 0} onChange={(e) => handleOnConversionValueChange(e, true)} />
                  </div>
                </div>
                {errors && errors.withdrawalAmountUSD && (
                  <ErrorMessage
                    className='text-danger error-msg login-input small mx-2'
                    message={errors.withdrawalAmountUSD.message}
                  />
                )}
                <div className='col-sm-12'>
                  <p className='pera-text text-center'>The value of {selectedCurrency?.currency?.code} may change between now and the time we process your payment</p>
                </div>
              </div>
              {/* <div className='col-sm-12'>
                <div className='d-flex justify-content-between'>
                  <label className='form-label'>Amount <span className='text-danger ms-1'>*</span></label>
                  <label className='form-label'><small>$0.00</small></label>
                </div>
                <div className='input-group mb-3'>
                  <input type='number' className='form-control icon-inside-right' value='0.00000000' readOnly />
                  <span className='icon'>
                    <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' />
                  </span>
                  <span className='addon addon-right cursor-pointer'>
                    <button type='button' className='input-btn'>
                      <span className='text'>Max</span>
                    </button>
                  </span>
                </div>
              </div> */}
              <div className='col-sm-12'>
                <div className='btn-box mt-2 mb-3'>
                  <button type='submit' className='btn secondary-btn w-100'>
                    {withdrawLoading
                      ? (
                        <Loader component={LOADER.BUTTON} />
                        )
                      : (
                          'Withdraw'
                        )}
                  </button>
                </div>
              </div>
              <div className='col-sm-12'>
                <p className='pera-text'>
                  Minimum withdrawal is 0.00020000 <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' />. Your withdrawal will have 0.00007000 <img src='/assets/images/stock-images/currency-eth.png' className='img-fluid' alt='Icon' /> subtracted from your remaining balance to cover the fee required to process the transaction.
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* FIRST CONTENT WRAP END */}
    </div>
  )
}

export default memo(WithdrawSection)
