// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard-container {
  background-color: var(--crashPixiBgColor);
  padding: 10px;
  max-height: 230px;
  overflow-y: auto;
  font-size: 14px;
  overflow-x: hidden;
  height: auto; }

.result-link-section {
  background-color: var(--crashPixiBgColor); }

.history-table {
  color: #b1bad3; }
  .history-table tr td, .history-table tr th {
    border: 0;
    padding: 1rem; }
    .history-table tr td:first-child, .history-table tr th:first-child {
      text-align: left;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
    .history-table tr td:last-child, .history-table tr th:last-child {
      text-align: right;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem; }
  .history-table tbody > tr:nth-of-type(odd) {
    background-color: var(--darkSlate); }

#AllroundHistoryModal .modal-dialog {
  max-width: 500px; }
  #AllroundHistoryModal .modal-dialog .modal-content {
    background-color: var(--mobileBtnDark); }
`, "",{"version":3,"sources":["webpack://./src/containers/AHOriginalsGames/CrashGame/CrashGameResultSection/AllRoundHistoryModal/AllRoundHistoryModal.scss"],"names":[],"mappings":"AAAA;EACI,yCAAyC;EACzC,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,YAAY,EAAA;;AAEhB;EACI,yCAAyC,EAAA;;AAE7C;EACI,cAAc,EAAA;EADlB;IAIY,SAAS;IACT,aAAa,EAAA;IALzB;MAOgB,gBAAgB;MAChB,+BAA+B;MAC/B,kCAAkC,EAAA;IATlD;MAYgB,iBAAiB;MACjB,gCAAgC;MAChC,mCAAmC,EAAA;EAdnD;IAmBQ,kCAAkC,EAAA;;AAG1C;EAEQ,gBAAgB,EAAA;EAFxB;IAIY,sCAAsC,EAAA","sourcesContent":[".leaderboard-container {\n    background-color: var(--crashPixiBgColor);\n    padding: 10px;\n    max-height: 230px;\n    overflow-y: auto;\n    font-size: 14px;\n    overflow-x: hidden;\n    height: auto;\n}\n.result-link-section {\n    background-color: var(--crashPixiBgColor);\n}\n.history-table {\n    color: #b1bad3;\n    tr {\n        td, th {\n            border: 0;\n            padding: 1rem;\n            &:first-child {\n                text-align: left;\n                border-top-left-radius: 0.25rem;\n                border-bottom-left-radius: 0.25rem;\n            }\n            &:last-child {\n                text-align: right;\n                border-top-right-radius: 0.25rem;\n                border-bottom-right-radius: 0.25rem;\n            }\n        }\n    }\n    tbody>tr:nth-of-type(odd) {\n        background-color: var(--darkSlate);\n    }\n}\n#AllroundHistoryModal {\n    .modal-dialog {\n        max-width: 500px;\n        .modal-content {\n            background-color: var(--mobileBtnDark);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
