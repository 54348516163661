import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSumsubTokenService, updateUserInformationLevelOneKycService } from '../../../network/services/sumsub.service'

export const getSumsubToken = createAsyncThunk(
  'fetch/get-sumsub-token',
  async (data, thunkApi) => {
    try {
      const res = await getSumsubTokenService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const levelOneVerificationDetails = createAsyncThunk(
  'level-one-update',
  async ({ data, handleCloseAccordion }, thunkApi) => {
    try {
      const res = await updateUserInformationLevelOneKycService(data)
      handleCloseAccordion()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
